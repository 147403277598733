import React from 'react';
import './main-button.scss';
function MainButton(props) {
    return (
        <button {...props}  className={"coach-main-button "+ (props["aria-disabled"] || props.disabled ? 'disabled ': '') + (props.type == 'line' ? ' line ' : '' ) + (props.className || '') + (props.short ? ' short' : ' long')}>
            {props.icon ?
                <img alt="" src={props.icon} />:''
            }
            {props.text}
        </button>
    );
}
  
export default MainButton;