const defaultCart = {
    productList: [],
    cartDetails: {},
    basketId: '',
    isBannerPromotionVisible: false,
    bannerPromotionText:'',
    taxDetail: {
      estimatedTax: '23.32'
    }
};

export default (state = defaultCart, action) => {
  switch (action.type) {
    case "SET_CART":
      return {
        ...state,
        productList: action.productList,
        cartDetails: action.cartDetails
      }
    case "SET_TAX":
      return {
        ...state,
        taxDetail: action.taxDetail,
      }
    case "SET_CART_BANNER_PROMOTION":
      return {
        ...state,
        isBannerPromotionVisible: action.isBannerPromotionVisible,
        bannerPromotionText: action.bannerPromotionText
      }
    case "SET_BASKET_ID":
      return {
        ...state,
        basketId: action.basketId
      }
    default:
      return state;
  }
};
