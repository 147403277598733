const defaultScan = {
    isEnterProductCodeVisible: false,
    isScanError: false,
    toastVisible: false,
    toastMessage: []
};

export default (state = defaultScan, action) => {
  switch (action.type) {
    case "SET_MANUAL_CODE_VISIBILITY":
        return {
            ...state,
            isEnterProductCodeVisible: action.isEnterProductCodeVisible
        }
    case "SET_SCAN_ERROR_VISIBILITY":
        return {
            ...state,
            isScanError: action.isScanError
        }
    case "SET_TOAST_VISIBILITY":
        return {
          ...state,
          toastVisible: action.toastVisible
        }
    case "SET_TOAST_MESSAGE":
        return {
            ...state,
            toastMessage: action.toastMessage
        }
    default:
      return state;
  }
};
