import CartService from "../services/cart.service";
import Util from "../utils/util";
import configureStore from '../store/configureStore';
const store = configureStore();
const selectedLanguage = Util.getSelectedLanguage();

const setTax = (storeId, request, dispatch) => {
  const storeDetail = Util.getStoreDetails();
    request.countryCode = storeDetail.countryCode;
    request.state = storeDetail.state||"";
  return CartService.getTaxDetail(storeId, request)
    .then(data => {
      if (data) {
        dispatch({
          type: "SET_TAX",
          taxDetail: data.taxDetail || {}
        });
      }
    })
    .catch(err => {
      console.log(err);
    });
};
const isAddedInWishlist = (itemId) => {
  let isAdded = false;
  const wishlistList = Util.getObjectFromSessionStorage(Util.LOADED_WISHLIST) || [];
  if (wishlistList && wishlistList.length > 0 && itemId && itemId) {
    wishlistList.forEach(wishlistItem => {
      if (wishlistItem && wishlistItem.itemId && wishlistItem.itemId == itemId) {
        isAdded = true;
      }
    });
  }

  return isAdded;
}
const getImpressions = (data) => {
  let list = [];
  if (data && data.length) {
    data.map((apiProduct, index) => {
      let cartProduct = {};
      if (apiProduct && apiProduct.productId) {
        cartProduct.name = apiProduct.styleName;
        cartProduct.id = apiProduct.itemId;
        cartProduct.price = Util.truncateDecimals(apiProduct.basketSalePrice,2);
        cartProduct.category = apiProduct.departmentNumber
        cartProduct.brand = 'coach';
        cartProduct.variant = Util.getVariant(apiProduct.styleCode, apiProduct.colorCode, apiProduct.sizeCode);
        cartProduct.styleNo = apiProduct.styleCode;
        cartProduct.color = apiProduct.colorName;
        cartProduct.size = apiProduct.sizeCode || undefined;
        cartProduct.isAvailable = '1';
        cartProduct.isWishlist = isAddedInWishlist(apiProduct.itemId) ? '1' : '0',
          cartProduct.isRunOff = '0';
        cartProduct.isRequested = apiProduct.isRequested ? '1' : '0';
        cartProduct.isRegular = apiProduct.isRequested ? '0' : '1';
      }
      list.push(cartProduct)
    });
    return list
  }
}
const rewardImpression = (additionalPromos, appliedBasketPromos) => {
  const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
  let appliedPromotions = [];
  let extraPromos =[];
  if (appliedBasketPromos && appliedBasketPromos.length) {
    appliedBasketPromos.map((item,index)=>{
      if(additionalPromos & additionalPromos.length)
      {
      additionalPromos.map((extra, index)=>{
        if(item.couponId != extra.couponCode){
          extraPromos = extraPromos.push(extra.couponCode)
        }
      })
      }
    })
    appliedPromotions = appliedBasketPromos.concat(extraPromos)
    

  }
  else {
    appliedPromotions = additionalPromos
  }

  let list = [];
  if (appliedPromotions && appliedPromotions.length) {
    appliedPromotions.map((item, index) => {
      if(perk && perk.length){
      perk.map((perkItem, index) => {
          perkItem.perkSubChannelInfo.map((perkSub, index) => {
            if (item.couponId && perkSub.couponCode == item.couponId){
        list.push(perkItem.perkId);
      }
    })
  })
}
})
if (list && list.length >0) {
  return list;
  
} else {
  return undefined;
  
}
  } else {
    return undefined;
  }
}

export const setCurrentCart = (storeId, request, isByUserAction = '') => {

  // to handle the isrequested label in cart product items
  const requestObj = {
    itemIds: [],
    couponNum: request.couponNum,
    userAppliedCouponNum: request.userAppliedCouponNum,
    isLoggedIn: request.isLoggedIn,
    personalizedPromosList: request.personalizedPromosList
  };

  request.itemIds.forEach(itemIdObj => {
    requestObj.itemIds.push(itemIdObj.itemId);
  });

  return (dispatch, getState) => {
    CartService.getCartDetails(storeId, requestObj)
      .then(data => {
        Util.clearCookie(Util.COOKIE_NAMES.PREVIOUS_PAGE);
        if (data) {
          if (!data.savings || data.savings <= 0) {
            data.savings = '0.00';
          }
          let promoName = ""
          data.additionalPromos.map((promos, index) => {
            if (promos.applied == "APPLIED" || promos.applied == "APPLIED") {
              promoName = promos.displayName
            }
          })
          dataLayer.push({
            'event': 'rewardInterection',
            'rewardAction': 'rewardImpresssion',
            'rewardCode': rewardImpression(data.additionalPromos, data.appliedBasketPromos),
            'location': 'checkout:bag'
          })
          // dataLayer.push({
          //       'event': 'rewardInterection',
          //        'rewardAction':'rewardSuccess',
          //        'rewardCode':promoName, 
          //        'location': 'cart' });

          //   // to handle the isrequested label in cart product items
          // data.itemList.forEach((itemResponseObj) => {
          //   request.itemIds.forEach((itemRequestObj) => {
          //     if (itemRequestObj.itemId == itemResponseObj.itemId && !itemRequestObj.isParesed) {
          //       itemRequestObj.isParesed = true;
          //       itemResponseObj.isRequested = itemRequestObj.isRequested;
          //     }    
          //   });
          // });
          // for (let i = 0; i < data.itemList.length; i++) {
          //   for (let j = 0; j < request.itemIds.length; j++) {
          //     if (data.itemList[i].itemId == request.itemIds[j].itemId && !request.itemIds[j].isParesed) {
          //       data.itemList[i].isRequested = request.itemIds[j].isRequested;
          //       request.itemIds[j].isParesed = true;
          //       break;
          //     }
          //   }
          // }
          
          if (data.itemList) {
            for (let i = 0; i < data.itemList.length; i++) {
              data.itemList[i].isRequested = request.itemIds[i].isRequested;
            } 
        }

          dispatch({
            type: "SET_CART",
            cartDetails: data,
            productList: data.itemList
          });
          dataLayer.push({
            event: "eCommerceLog",
            eCommerceCheckoutStage: "view_cart",
            ecommerce: {
              currencyCode: "USD",
              impressions: getImpressions(data.itemList),
            }
          })

          setTax(storeId, data, dispatch);

          const personalizedPromosList = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST) || [];
          const personalPromoNames = [];
          if (data && (data.additionalPromos || data.appliedBasketPromos)) {
            const additionalPromos = data.additionalPromos || [];
            const appliedbasketPromos = data.appliedBasketPromos || [];
            additionalPromos.forEach(promo => {
              if (promo && promo.couponId && personalizedPromosList.indexOf(promo.couponId) >= 0) {
                personalPromoNames.push(promo.displayName);
              }
            });
            appliedbasketPromos.forEach(promo => {
              if (promo && promo.couponId && personalizedPromosList.indexOf(promo.couponId) >= 0) {
                if (personalPromoNames.indexOf(promo.displayName) < 0) {
                  personalPromoNames.push(promo.displayName);
                }
              }
            });
            // if (personalPromoNames && personalPromoNames.length > -1) {
            //   dataLayer.push({
            //     event: 'personalizedOffersShown',
            //     personalizedOffer: personalPromoNames.join('|')
            //   });
            // }
          }

          // Normalizing Cart product data
          const currentCart = Util.getCart();
          currentCart.forEach((cartProduct, index) => {
            data.itemList.forEach(apiProduct => {
              if (apiProduct.itemId == cartProduct.itemId) {
                cartProduct.ga_name = apiProduct.styleName;
                cartProduct.ga_id = apiProduct.itemId;
                cartProduct.ga_price = apiProduct.basketSalePrice;
                cartProduct.ga_brand = 'brand';
                cartProduct.ga_variant = Util.getVariant(apiProduct.styleCode, apiProduct.colorCode, apiProduct.sizeCode);
                cartProduct.ga_quantity = 1;
                cartProduct.ga_dimension6 = apiProduct.colorCode;
                cartProduct.ga_dimension7 = apiProduct.sizeCode;
                cartProduct.ga_dimension8 = 'checkout:bag';
                cartProduct.ga_appliedPromotions = apiProduct.appliedPromotions
              }
            });
          });

          // ADDING BASKET APPLIED PROMOS TO STORAGE
          Util.addObjectToStorage(
            Util.APPLIED_BASKET_PROMOS,
            data.appliedBasketPromos || []
          );
          ////////////////////////

          Util.setCart(currentCart);
          ///////////
          const store = getState();
          const languageData = (store && store.locale && store.locale.screens ? store.locale.screens.cart : {});
          let statusPromo;
          let autoAppliedPromo;
          if (data.additionalPromos && data.additionalPromos.length) {
            data.additionalPromos.forEach(promo => {
              if (promo.applied == 'AUTO_APPLIED') {
                autoAppliedPromo = promo;
              } else if (promo.applied != 'NOT_SET') {
                statusPromo = promo;
              }
            });
          }

          // RE-SETTING AUTO APPLY PROMOTION IF NO AUTO APPLYED PROMO IS AVAILABLE
          if (!autoAppliedPromo) {
            Util.removeStringFromStorage(Util.ADDITIONAL_AUTO_APPLIED_COUPON);
          }
          ////////////////////

          // Display auto apply offer message
          const autoAppliedCoupon = Util.getObjectFromStorage(Util.ADDITIONAL_AUTO_APPLIED_COUPON);
          if (autoAppliedPromo && autoAppliedPromo.applied == 'AUTO_APPLIED' && (!autoAppliedCoupon || autoAppliedCoupon.couponId != autoAppliedPromo.couponId)) {
            window.toggleCommonToast(true, [languageData.additionalOfferAutoApplyMessage,
            (autoAppliedPromo.promoNameTranslations[selectedLanguage] || autoAppliedPromo.displayName)], false, 7000);
          }
          //////////////////

          // Managing additional promo toasts
          if (isByUserAction && currentCart && currentCart.length) {
            if (data.additionalPromos && data.additionalPromos.length) {
              if (statusPromo && statusPromo.applied == 'APPLIED' && isByUserAction == Util.OFFER_ACTION_APPLY) {

                // dataLayer.push({
                //   'event': 'rewardInterection',
                //   'rewardAction': 'rewardSuccess',
                //   'rewardCode': statusPromo.displayName,
                //   'location': 'checkout:bag'
                // });
                const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
                let rewardID = "";
                if (perk && perk.length) {
                  perk.map((perkItem, index) => {
                    perkItem.perkSubChannelInfo.map((perkSub, index) => {
                      if (perkSub.couponCode == statusPromo.couponId){
                          rewardID =perkItem.perkId;
                      }
                    })
                          })
                }
                if(rewardID)
                {
                  dataLayer.push({  
                    'event': 'rewardInterection', 
                    'rewardAction':'rewardSuccess',
                    'rewardCode': rewardID, 
                    'location': 'cart'
                  });
                }

                window.toggleCommonToast(true, [languageData.offerAppliedMessage1,
                languageData.offerAppliedMessage2], false, 5000);
                Util.removeStringFromStorage(Util.OFFER_IS_ERROR_SHOWED);

              } else if (statusPromo && statusPromo.applied == 'NOT_ELIGIBLE') {
                // dataLayer.push({
                //   'event': 'rewardInterection',
                //   'rewardAction': 'rewardInvalid',
                //   'rewardCode': statusPromo.displayName,
                //   'location': 'checkout:bag'
                // })
                const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
                let rewardID = "";
                if (perk && perk.length) {
                  perk.map((perkItem, index) => {
                    perkItem.perkSubChannelInfo.map((perkSub, index) => {
                      if (perkSub.couponCode == statusPromo.couponId){
                          rewardID =perkItem.perkId;
                      }
                    })
                          })
                }
                if(rewardID)
                {
                  dataLayer.push({  
                    'event': 'rewardInterection', 
                    'rewardAction':'rewardInvalid',
                    'rewardCode': rewardID, 
                    'location': 'cart'
                  });
                }
                const isErrorShowed = Util.getStringFromStorage(Util.OFFER_IS_ERROR_SHOWED);
                if (Util.getStringFromStorage(Util.ADDITIONAL_APPLIED_COUPON) && !isErrorShowed) {
                  window.toggleCommonToast(true, [languageData.addtionalOfferError3], true, 7000);
                } else if (!Util.getStringFromStorage(Util.ADDITIONAL_APPLIED_COUPON) && !isErrorShowed) {
                  window.toggleCommonToast(true, [
                    `${statusPromo.promoNameTranslations[selectedLanguage]} ${languageData.addtionalOfferError1}`,
                    languageData.addtionalOfferError2], true, 5000);
                }
                Util.addStringToStorage(Util.OFFER_IS_ERROR_SHOWED, true);
              }
            }
          }

          // ADDING AUTO APPLIED PROMO TO STORAGE
          if (data.additionalPromos && data.additionalPromos.length) {
            data.additionalPromos.forEach((aPromo) => {
              if (aPromo.applied === 'APPLIED') {
                Util.removeStringFromStorage(Util.OFFER_IS_ERROR_SHOWED);
                Util.addStringToStorage(
                  Util.ADDITIONAL_APPLIED_COUPON,
                  aPromo.couponId
                );
              } else if (aPromo.applied === 'AUTO_APPLIED') {
                Util.removeStringFromStorage(Util.OFFER_IS_ERROR_SHOWED);
                Util.addObjectToStorage(
                  Util.ADDITIONAL_AUTO_APPLIED_COUPON,
                  aPromo
                );
              }
            });
          }
          ////////////////////////////////////////

          if (data.nextTierPromotion && data.nextTierPromotion.length) {
            const tierPromotionData = data.nextTierPromotion[0];
            const storeDetail = Util.getStoreDetails();
            if (
              storeDetail &&
              storeDetail.config &&
              storeDetail.config.shouldShowThresholdMessage &&
              storeDetail.config.shouldShowThresholdMessage == "true" &&
              storeDetail.config.thresholdMessageFormat
            ) {
              let finalBannerText = storeDetail.config.thresholdMessageFormat;
              finalBannerText = finalBannerText.replace(
                "#{missedDiscount}",
                tierPromotionData.missedDiscount
              );
              finalBannerText = finalBannerText.replace(
                "#{nextThreshold}",
                tierPromotionData.nextThreshold
              );
              dispatch({
                type: "SET_CART_BANNER_PROMOTION",
                isBannerPromotionVisible: true,
                bannerPromotionText: finalBannerText
              });
            }
          }
        }
      })
      .catch(e => {
        console.log(e);
        // window.location.href = "/error";
      });
  };
};
const rewardSavings = (data) => {
  const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
  let list = [];
  if (data && data.length && perk && perk.length) {
    data.map((coupon, index) => {
      if (coupon.couponId) {
        perk.map((perkItem, index) => {
          perkItem.perkSubChannelInfo.map((perkSub, index) => {
            if (perkSub.couponCode == coupon.couponId){
              list.push(Util.truncateDecimals(coupon.discountAMT, 2));
            }
          })
                })
              }
            });
            return list
          }
          else
            return undefined
  // let list = [];
  // if (data && data.length) {
  //   data.map((item, index) => {
  //     if (item.discountAMT) {
  //       list.push(item.discountAMT);
  //     }
  //   });
  // }
  // return list;
}
const rewardIds =(data)=>{
  const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
  let list = [];
  if (data && data.length && perk && perk.length) {
    data.map((coupon, index) => {
      if (coupon.couponId) {
        perk.map((perkItem, index) => {
          perkItem.perkSubChannelInfo.map((perkSub, index) => {
            if (perkSub.couponCode == coupon.couponId){
              list.push(perkItem.perkId);
            }
          })
                })
              }
            });
            return list
          }
          else
            return undefined
  // if (data && data.length && rewards && rewards.length) {
  //   data.map((coupon, index) => {
  //     if (coupon.couponId) {
  //       rewards.map((item, index) => {
  //         if (item == coupon.couponId)
  //           list.push(coupon.couponId);
  //       })
  //     }
  //   });
  //   return list
  // }
  // else
  //   return undefined
}
const couponIds = (data, pipe = false) => {
  let appliedCoupons = {...data}
  let rewards = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST);
  let list = [];
  if (appliedCoupons && appliedCoupons.length) {
    if (rewards && rewards.length) {
      rewards.map((couponId, index) => {
        if (couponId) {
          appliedCoupons.map((item, index) => {
            if (item.couponId && item.couponId == couponId)
            appliedCoupons.splice(index, 1);
            // list.push(item.couponId);
          })
        }
      });
    }
    // else {
    //   appliedPromotions.map((item, index) => {
    //     if (item.couponId) {
    //       list.push(item.couponId);
    //     }
    //   });
    // }
  }
  else
    return undefined
    appliedCoupons.map((item, index) => {
    if (item.couponId) {
      list.push(item.couponId);
    }
  });
  if (!pipe) {
    return list;
  } else {
    return list.join('|');
  }
}
const rewardDiscount = () => {
  const appliedPromotions = Util.getObjectFromStorage(Util.APPLIED_BASKET_PROMOS);
  const rewards = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST);
  let totalDiscount = 0;
  if (appliedPromotions && appliedPromotions.length && rewards && rewards.length) {
    appliedPromotions.map((coupon, index) => {
      if (coupon.couponId) {
        rewards.map((item, index) => {
          if (item == coupon.couponId)
          totalDiscount += coupon.discountAMT
        })
      }
    });
    return totalDiscount
  }
  else
    return undefined
  // const appliedPromotions = Util.getObjectFromStorage(Util.APPLIED_BASKET_PROMOS);
  // if (appliedPromotions && appliedPromotions.length) {
  //   appliedPromotions.map((item, index) => {
  //     if (item.discountAMT) {
  //       totalDiscount += item.discountAMT;
  //     }
  //   });
  // }
  // return totalDiscount;
}
const basketAppliedPromotions = (pipe = false) => {
  let appliedPromotions = Util.getObjectFromStorage(Util.APPLIED_BASKET_PROMOS);
  let rewards = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST);
  let list = [];
  if (appliedPromotions && appliedPromotions.length) {
    if (rewards && rewards.length) {
      rewards.map((couponId, index) => {
        if (couponId) {
          appliedPromotions.map((item, index) => {
            if (item.couponId && item.couponId == couponId)
              appliedPromotions.splice(index, 1);
            // list.push(item.couponId);
          })
        }
      });
    }
    // else {
    //   appliedPromotions.map((item, index) => {
    //     if (item.couponId) {
    //       list.push(item.couponId);
    //     }
    //   });
    // }
  }
  else
    return undefined
  appliedPromotions.map((item, index) => {
    if (item.couponId) {
      list.push(item.couponId);
    }
  });
  if (!pipe) {
    return list;
  } else {
    return list.join('|');
  }
}
const basketAppliedRewards = () => {
  const appliedPromotions = Util.getObjectFromStorage(Util.APPLIED_BASKET_PROMOS);
  const rewards = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST);
  const perk = Util.getObjectFromSessionStorage(Util.PERK_LIST);
  let list = [];
  if (appliedPromotions && appliedPromotions.length && perk && perk.length) {
    appliedPromotions.map((coupon, index) => {
      if (coupon.couponId) {
        perk.map((perkItem, index) => {
          perkItem.perkSubChannelInfo.map((perkSub, index) => {
            if (perkSub.couponCode == coupon.couponId){
              list.push(perkItem.perkId);
            }
          })
                })
              }
            });
            return list
          }
          else
            return undefined
}

export const passTheBasket = (storeId, request) => {
  return (dispatch, getState) => {
    if (request.itemIds && request.itemIds.length) {
      CartService.passTheBasket(storeId, request)
        .then(data => {
          Util.addObjectToStorage(
            Util.SERVER_DATE,
            data.date
          );
          const cartItems = Util.getCart();
          const analyticsItems = [];
          const requestedProductsInOrder = [];
          const requestedProducts = Util.getObjectFromSessionStorage(Util.INLINE_REQUESTED_PRODUCT_IDS) || [];
          if (requestedProducts && cartItems) {
            requestedProducts.forEach(requestProduct => {
              cartItems.forEach(cartItem => {
                if (requestProduct == cartItem.itemId) {
                  requestedProductsInOrder.push(requestProduct);
                }
              });
            });
        }
          cartItems.map(item => {
            analyticsItems.push({
              'name': item.ga_name,
              'id': item.ga_id,
              'price': Util.truncateDecimals(item.ga_price, 2),
              'brand': item.ga_brand,
              'variant': item.ga_variant,
              'quantity': item.ga_quantity,
              'coupon': couponIds(item.ga_appliedPromotions, true),
              'reward': rewardIds(item.ga_appliedPromotions) || undefined ,
              'rewardDiscount': rewardSavings(item.ga_appliedPromotions) || undefined,
              'dimension6': item.ga_dimension6 ? item.ga_dimension6 : undefined,
              'dimension7': item.ga_dimension7 ? item.ga_dimension7 : undefined,
              'dimension8': item.ga_dimension8 ? item.ga_dimension8 : undefined,
              'isRequested': item.isRequested ? "1" : "0",
              'isRegular': item.isRequested ? "0" : "1"
            });
          });
          // Google Analytics: Log purchases
          dataLayer.push({
            event: "eCommerceLog",
            eCommerceCheckoutStage: "purchase",
            ecommerce: {
              currencyCode: Util.getCurrencyCode(),
              purchase: {
                actionField: {
                  id: new Date().getTime(),
                  coupon: basketAppliedPromotions(true) || undefined,
                  reward: basketAppliedRewards(),
                  totalRewardDiscount: rewardDiscount(),
                  isRequestedOrder: requestedProductsInOrder.length ? "1" : "0"

                },
                products: analyticsItems
              }
            }
          });

          if (data && data.status === "Success") {
            const storeDetail = Util.getStoreDetails();
            if (
              storeDetail &&
              storeDetail.config &&
              storeDetail.config.needClearCart == "true"
            ) {
              Util.clearCart();
              Util.clearCoupons();
            }
            Util.addStringToStorage(
              Util.BASKET_ID,
              data.basketId || "000000000"
            );
            dispatch({
              type: "SET_BASKET_ID",
              basketId: data.basketId || "000000000"
            });
          } else {
            window.location.href = "/cart";
          }
        })
        .catch(e => {
          console.log(e);
          // window.location.href = "/error";
        });
    } else {
      // window.location.href = "/error";
    }
  };
};
export const clearCart = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_CART",
      cartDetails: {},
      productList: []
    });
  };
};

