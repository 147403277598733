import React, { Component } from "react";
import PropTypes from "prop-types";
import { configure, BarcodePicker as ScanditSDKBarcodePicker } from "scandit-sdk";
import Util from '../../utils/util';

// Configure the library and activate it with a license key


const style = {};

class ScanditScanner extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    playSoundOnScan: PropTypes.bool,
    vibrateOnScan: PropTypes.bool,
    scanningPaused: PropTypes.bool,
    guiStyle: PropTypes.string,
    videoFit: PropTypes.string,
    scanSettings: PropTypes.object,
    enableCameraSwitcher: PropTypes.bool,
    enableTorchToggle: PropTypes.bool,
    enableTapToFocus: PropTypes.bool,
    enablePinchToZoom: PropTypes.bool,
    accessCamera: PropTypes.bool,
    camera: PropTypes.object,
    cameraSettings: PropTypes.object,
    targetScanningFPS: PropTypes.number,
    onScan: PropTypes.func,
    onError: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.scanningPaused != this.props.scanningPaused) {
      window.barcodePicker.pauseScanning(this.props.scanningPaused);
      if (!this.props.scanningPaused) {
        window.barcodePicker.accessCamera().then(picker => {
          window.barcodePicker = picker;
        })
        .catch(err => {
          alert(JSON.stringify(err));
          console.log(err);
        });
      }
    }
  }
  componentDidMount() {
    if (!window.barcodePicker) {
      configure(Util.SCANDIT_LICENSE_KEY, {
        engineLocation: Util.SCANDIT_ENGINE_LOCATION
    }).then(() => {

        ScanditSDKBarcodePicker.create(this.ref.current, this.props).then(barcodePicker => {
          if (this.props.onReady != null) {
              barcodePicker.on("ready", this.props.onReady);
          }
          if (this.props.onProcessFrame != null) {
              barcodePicker.on("processFrame", this.props.onProcessFrame);
          }
          if (this.props.onScan != null) {
              barcodePicker.on("scan", this.props.onScan);
          }
          if (this.props.onError != null) {
              barcodePicker.on("scanError", this.props.onError);
          }
          window.barcodePicker = barcodePicker;
        }).catch(err => {
          alert(JSON.stringify(err));
          if (this.props.onError) {
            this.props.onError(err);
          }
        });
    })
    .catch(error => {
      console.log(error);
      alert(JSON.stringify(error));
      dataLayer.push({
        errorValue: "Scandit Scanner Error",
        event: "errorLogged"
      });
    });
    }
  }

  componentWillUnmount() { 
    if (window.barcodePicker != null) {
      // window.barcodePicker.destroy();
      // window.barcodePicker.pauseScanning(true);
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.scanSettings) !== JSON.stringify(this.props.scanSettings)) {
      window.barcodePicker.applyScanSettings(this.props.scanSettings);
    }
    if (prevProps.visible !== this.props.visible) {
      window.barcodePicker.setVisible(this.props.visible);
    }
  }

  render() {
    return <div aria-hidden="true" ref={this.ref} style={style} />;
  }
}

export default ScanditScanner;