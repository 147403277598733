import React from 'react';
import bannerLogoImage from '../../static/banner-logo.png';
import bannerLogoWhite from '../../static/banner-logo-white.svg';
import './logo.scss';
function BannerLogo(props) {
    return (
        <div className={"logo-container "+props.className}>
            {props.color === 'white' ?
                <img alt={props.commonTextData.CoachOutlet || ""} src={bannerLogoWhite} />:
                <img alt={props.commonTextData.CoachOutlet || ""} src={bannerLogoImage} />
            }
        </div>
    );
}
  
export default BannerLogo;