const defaultLocale = {};

export default (state = defaultLocale, action) => {
  switch (action.type) {
    case "SET_LOCALE":
      return action.locale;
    default:
      return state;
  }
};
