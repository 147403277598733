import React from 'react';
import './full-screen-modal.scss';
import closeIcon from '../../static/close.svg';
function FullScreenModal({showCloseButton = true, ...props}) {
    setTimeout(()=>{
        var terms = document.getElementById("close");
        if (terms) {
            terms.focus();
        }
    },100) 
    return (
        <div tabIndex="0" className={"full-screen-modal-container "+(props.className || '')}>
            { showCloseButton && <img id="close" tabindex="0" aria-label="Close this page" role="button" src={closeIcon} className="close" onClick={() => props.onClose()}/> }
            <div className="content-container">
                {props.children}
            </div>
        </div>
    );
}

export default FullScreenModal;