import React from "react";
import "./common-toast.scss";
import Util from "../../utils/util";
import MainLink from "../main-link/main-link";
import closeIcon from '../../static/close.svg';
class CommonToast extends React.Component {
  render() {
    const {
      isError,
      message,
      toastType,
      actionLabel,
      actionCallback,
      toastHtmlContent,
      hasClose
    } = this.props;
    const language = Util.getSelectedLanguage();
    // const isError = false;
    // let toastType = Util.TOAST_TYPE.HTML;
    // let actionLabel = "clickme";
    // let actionCallback = () => console.log("clickde");
    // console.log(message);
    // let message = ["hsdjkbf.skdjbf.s"];

    return (
      <div tabIndex="0" aria-live="assertive" role="alert" aria-atomic="true" className={"common-toast-container " + (isError ? "error" : "")}>
        {hasClose ?
          <div className="close-container" onClick={() => window.toggleCommonToast(false)}>
            <img src={closeIcon} />
          </div>:''
        }
        {toastType === Util.TOAST_TYPE.NORMAL
          ? message && message.length
            ? message.map((message, index) => {
                return <span key={index}>{message}</span>;
              })
            : ""
          : ""}
        {toastType === Util.TOAST_TYPE.WITH_ACTION
          ? message && message.length
            ? message.map((message, index) => {
                return (
                  <div className="with-action-container" key={index}>
                    <div className="message-container">
                      <span className={"message "+language}>{message}</span>
                    </div>
                    <div className="action-container">
                      <span className="action-label" onClick={actionCallback}>
                        {actionLabel}
                      </span>
                    </div>
                  </div>
                );
              })
            : ""
          : ""}

        {toastType === Util.TOAST_TYPE.HTML
          ? message && message.length
            ? message.map((message, index) => {
                return (
                  <div key={index}>
                    <span dangerouslySetInnerHTML={{ __html: message }}></span>
                    {/* <div className="html-content-container">
                      <span
                        dangerouslySetInnerHTML={{ __html: toastHtmlContent }}
                      ></span>
                    </div> */}
                  </div>
                );
              })
            : ""
          : ""}
        
        {toastType === Util.TOAST_TYPE.WITH_LINK ? 
          <div className="with-action-link-container">
            {message && message.length
            ? message.map((message, index) => {
                return (
                    <div className="message-container" key={index}>
                      <span className={"message "+language}>{message}</span>
                    </div>
                );
              }) : ''}
              <div className="action-container">
                <MainLink className="action-label" onClick={() => actionCallback()}>{actionLabel}</MainLink>
              </div>
          </div>:''
        }
      </div>
    );
  }
}

export default CommonToast;
