import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import storeReducer from "../reducers/store";
import cartReducer from "../reducers/cart";
import productReducer from "../reducers/product";
import scanReducer from "../reducers/scan";
import localeReducer from "../reducers/locale";
import offersReducer from "../reducers/offers";
import userReducer from "../reducers/user";

export default () => {
  const store = createStore(
    combineReducers({
      store: storeReducer,
      cart: cartReducer,
      product: productReducer,
      scan: scanReducer,
      locale: localeReducer,
      offers: offersReducer,
      user: userReducer
    }),
    applyMiddleware(thunk)
  );

  return store;
};
