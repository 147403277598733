import base64 from "base-64";
import { sha256 } from "js-sha256";

export default class Util {
  static DATALAB_CUSTOMER_ID = base64.encode("__COACH_DATALAB_CUSTOMER_ID");
  static CART = base64.encode("__COACH_CART_PRODUCTS");
  static CAMERA_PERMISSION_FLAG = base64.encode(
    "__COACH_CAMERA_PERMISSION_FLAG"
  );
  static STORE_DETAILS = base64.encode("__COACH_STORE_DETAILS"); // X19DT0FDSF9TVE9SRV9ERVRBSUxT
  static APPLIED_COUPONS = base64.encode("__COACH_APPLIED_COUPONS"); // X19DT0FDSF9BUFBMSUVEX0NPVVBPTlM=
  static ADDITIONAL_APPLIED_COUPON = base64.encode(
    "__COACH_ADDITIONAL_APPLIED_COUPON"
  );
  static ADDITIONAL_AUTO_APPLIED_COUPON = base64.encode(
    "__COACH_ADDITIONAL_AUTO_APPLIED_COUPON"
  );
  static BASKET_ID = base64.encode("__COACH_BASKET_ID");
  static TIMEOUT_TIMESTAMP = base64.encode("__COACH_TIMEOUT_TIMESTAMP"); // X19DT0FDSF9USU1FT1VUX1RJTUVTVEFNUA==
  static EMAIL_SUBSCRIPTION_FLAG = base64.encode(
    "__COACH_EMAIL_SUBSCRIPTION_FLAG"
  );
  static USER_EMAIL = base64.encode("__COACH_USER_EMAIL");
  static APP_LANGUAGE = base64.encode("__COACH_LANGUAGE"); // X19DT0FDSF9MQU5HVUFHRQ==
  static STORE_TOKEN = base64.encode("__COACH_STORE_TOKEN"); // X19DT0FDSF9TVE9SRV9UT0tFTg==
  static SFCC_TOKEN = base64.encode("__COACH_SFCC_TOKEN"); // X19DT0FDSF9TRkNDX1RPS0VO
  static STORE_PIN = base64.encode("__COACH_STORE_PIN"); // X19DT0FDSF9TVE9SRV9QSU4=
  static BASKET_APPLIED_PROMOS = base64.encode(
    "__COACH_BASKET_APPLIED_COUPONS"
  ); // X19DT0FDSF9CQVNLRVRfQVBQTElFRF9DT1VQT05T
  static COLOR_SWATCH_FLAG = base64.encode("__COACH_COLOR_SWATCH_FLAG"); // X19DT0FDSF9DT0xPUl9TV0FUQ0hfRkxBRw==
  static OFFER_IS_ERROR_SHOWED = base64.encode(
    "__COACH_OFFER_ERROR_MESSAGE_SHOWED"
  ); // X19DT0FDSF9PRkZFUl9FUlJPUl9NRVNTQUdFX1NIT1dFRA==
  static OFFER_IS_AUTO_APPLY_MSG_SHOWED = base64.encode(
    "__COACH_OFFER_IS_AUTO_APPLY_MSG_SHOWED"
  ); // X19DT0FDSF9PRkZFUl9FUlJPUl9NRVNTQUdFX1NIT1dFRA==
  static IS_HOME_TERMS_CLOSED = base64.encode("__IS_HOME_TERMS_CLOSED");
  static APPLIED_BASKET_PROMOS = base64.encode("__COACH_APPLIED_BASKET_PROMOS");
  static LOGGEDIN_USER_DETAILS = base64.encode("__COACH_LOGGEDIN_USER_DETAILS");
  static NEED_REMEMBER_USER = base64.encode("__COACH_NEED_REMEMBER_USER");
  static LOADED_WISHLIST = base64.encode("__COACH_LOADED_WISHLIST"); // X19DT0FDSF9MT0FERURfV0lTSExJU1Q=
  static USER_CUSTOM_ATTRIBUTE = base64.encode("__COACH_USER_CUSTOM_ATTRIBUTE");
  static SIGNUP_PROMO = base64.encode("__COACH_SIGNUP_PROMO");
  static PERSONALIZED_PROMO_LIST = base64.encode(
    "__COACH_PERSONALIZED_PROMO_LIST"
  );
  static PERK_LIST = base64.encode("__COACH_PERK_LIST");
  static IS_ASSOCIATE = base64.encode("__COACH_IS_ASSOCIATE"); //
  static LOCAL_WISHLIST = base64.encode("__COACH_LOCAL_WISHLIST"); // X19DT0FDSF9MT0NBTF9XSVNITElTVA==
  static WISHLIST_PENDING_SYNC_ITEMS = base64.encode(
    "__COACH_WISHLIST_PENDING_SYNC_ITEMS"
  );
  static INTRO_CAROUSEL_SHOWN = base64.encode("__COACH_INTRO_CAROUSEL_SHOWN");
  static INLINE_REQUESTED_PRODUCT_IDS = base64.encode(
    "__COACH_INLINE_REQUESTED_PRODUCT_IDS"
  );
  static PRODUCT_LIST_SCROLL_ID = base64.encode(
    "__COACH_PRODUCT_LIST_SCROLL_ID"
  );
  static PRODUCT_LIST_LOADED_COUNT = base64.encode(
    "__COACH_PRODUCT_LIST_LOADED_COUNT"
  );
  static PRODUCT_LIST_SCROLL_POSITION_FOR_PDP = base64.encode(
    "__COACH_PRODUCT_LIST_SCROLL_POSITION_FOR_PDP"
  );
  static REQUEST_INLINE_BUTTON_VISIBLE = base64.encode(
    "__COACH_REQUEST_INLINE_BUTTON_VISIBLE"
  );
  static HIDE_SAVE_TO_BAG_BUTTON = base64.encode(
    "__COACH_HIDE_SAVE_TO_BAG_BUTTON"
  );
  static IS_ECATALOGUE_FLOW = base64.encode("__COACH_IS_ECATALOGUE_FLOW");
  static SHOW_ATTENTIVE_FLOATING_POPUP = base64.encode(
    "__COACH_SHOW_ATTENTIVE_FLOATING_POPUP"
  );
  static INSIDER_GATE_GROUP = base64.encode("__COACH_INSIDER_GATE_GROUP");
  static IS_SIGNUP_SESSION_LOGGED_OUT = base64.encode(
    "__SIGNUP_SESSION_LOGGED_OUT "
  );
  static TIMEOUT_DURATION = 60000; // in Milliseconds
  static POST_EMAIL_CAPTURE = "POST";
  static PRE_EMAIL_CAPTURE = "PRE";
  static SERVER_DATE = base64.encode("__COACH_SERVER_DATE");
  static PRODUCT_BARCODE_COUNT_1 = 12;
  static PRODUCT_BARCODE_COUNT_2 = 13;
  static COUPON_BARCODE_COUNT = 10;
  static SCANNER_PRODUCT_TYPE = "product";
  static SCANNER_OFFER_TYPE = "offer";

  static SCANDIT_LICENSE_KEY = process.env.REACT_APP_SCANDIT_LICENSE_KEY;

  static SCANDIT_ENGINE_LOCATION =
    "https://cdn.jsdelivr.net/npm/scandit-sdk@5.6.0/build";
  static NO_NETWORK_LABEL =
    "Failed to fetch, The internet connection appears to be offline";
  static TOAST_TIMEOUT = 5000;
  static OFFER_ACTION_APPLY = "APPLIED";
  static OFFER_ACTION_REMOVED = "REMOVED";
  static LOCALE_VALUES_LIST = [
    {
      label: "English",
      value: "en-us",
    },
    {
      label: "简体中文",
      value: "zh",
    },
    {
      label: "Español",
      value: "es",
    },
    {
      label: "한국어",
      value: "ko",
    },
    {
      label: "Português",
      value: "pt",
    },
    {
      label: "Français",
      value: "fr",
    },
  ];
  static MONTH_DATE = [
    {
      monthLabel: "January",
      monthValue: "01",
      lastDate: 31,
      translations: {
        "en-us": "January",
        zh: "一月",
        es: "enero",
        ko: "일월",
        pt: "Janeiro",
        fr: "janvier",
      },
    },
    {
      monthLabel: "February",
      monthValue: "02",
      lastDate: 29,
      translations: {
        "en-us": "February",
        zh: "二月",
        es: "Febrero",
        ko: "이월",
        pt: "Fevereiro",
        fr: "février",
      },
    },
    {
      monthLabel: "March",
      monthValue: "03",
      lastDate: 31,
      translations: {
        "en-us": "March",
        zh: "游行",
        es: "marzo",
        ko: "삼월",
        pt: "Março",
        fr: "mars",
      },
    },
    {
      monthLabel: "April",
      monthValue: "04",
      lastDate: 30,
      translations: {
        "en-us": "April",
        zh: "四月",
        es: "Abril",
        ko: "사월",
        pt: "Abril",
        fr: "avril",
      },
    },
    {
      monthLabel: "May",
      monthValue: "05",
      lastDate: 31,
      translations: {
        "en-us": "May",
        zh: "可以",
        es: "Mayo",
        ko: "오월",
        pt: "Maio",
        fr: "mai",
      },
    },
    {
      monthLabel: "June",
      monthValue: "06",
      lastDate: 30,
      translations: {
        "en-us": "June",
        zh: "六月",
        es: "junio",
        ko: "유월 ",
        pt: "Junho",
        fr: "juin",
      },
    },
    {
      monthLabel: "July",
      monthValue: "07",
      lastDate: 31,
      translations: {
        "en-us": "July",
        zh: "七月",
        es: "julio",
        ko: "칠월",
        pt: "Julho",
        fr: "juillet",
      },
    },
    {
      monthLabel: "August",
      monthValue: "08",
      lastDate: 31,
      translations: {
        "en-us": "August",
        zh: "八月",
        es: "agosto",
        ko: "팔월",
        pt: "Agosto",
        fr: "août",
      },
    },
    {
      monthLabel: "September",
      monthValue: "09",
      lastDate: 30,
      translations: {
        "en-us": "September",
        zh: "九月",
        es: "septiembre",
        ko: "구월",
        pt: "Setembro",
        fr: "septembre",
      },
    },
    {
      monthLabel: "October",
      monthValue: "10",
      lastDate: 31,
      translations: {
        "en-us": "October",
        zh: "十月",
        es: "octubre",
        ko: "시월",
        pt: "Outubro",
        fr: "octobre",
      },
    },
    {
      monthLabel: "November",
      monthValue: "11",
      lastDate: 30,
      translations: {
        "en-us": "November",
        zh: "十一月",
        es: "noviembre",
        ko: "십일월",
        pt: "Novembro",
        fr: "novembre",
      },
    },
    {
      monthLabel: "December",
      monthValue: "12",
      lastDate: 31,
      translations: {
        "en-us": "December",
        zh: "十二月",
        es: "diciembre",
        ko: "십이월",
        pt: "Dezembro",
        fr: "décembre",
      },
    },
  ];
  static LOGGEDIN_STATUS = {
    LOGGED_IN: "YES",
    PARTIALLY_LOGGED_IN: "PARTIAL",
    LOGGED_OUT: "NO",
  };
  static TOAST_TYPE = {
    NORMAL: "1",
    WITH_ACTION: "2",
    HTML: "3",
    WITH_LINK: "4",
  };
  static BOTTOM_NAVIGATION_APPROVED_URLS = [
    "/offers",
    "/product/",
    "/cart",
    "/checkout",
    "/account",
    "/home",
    "/product-listing",
    "/product-categories",
  ];
  static FEEDBACK_SURVEY_POPUP_APPROVED_URLS = [
    "/offers",
    "/product/",
    "/checkout",
    "/account",
    "/home",
    "/product-listing",
    "/product-categories",
  ];
  static PRODUCT_AVAILABILITY = {
    ALMOST_GONE: "_almost_gone",
    SOLD_OUT: "_sold_out",
    AVAILABLE: "_available",
  };
  static INSIDER_GATE_GROUPS = {
    WITH_INSIDER_GATE_AND_INCENTIVE: "WITH_INSIDER_GATE_AND_INCENTIVE",
    WITH_INSIDER_GATE_NO_INCENTIVE: "WITH_INSIDER_GATE_NO_INCENTIVE",
    WITHOUT_INSIDER_GATE: "WITHOUT_INSIDER_GATE",
  };
  static OVERLAY_EXCEPTION_URL = [
    "/home",
    "/browser-not-supported",
    "/browser-incompatible",
  ];
  static DEFAULT_PLP_PRODUCTS_COUNT = 12;
  static FORGOT_PASSWORD_LINK = "https://www.coachoutlet.com/reset-password";
  static WISHLIST_PAGE_COUNT = 20;
  static SFCC_TOKEN_EXPIRY_TIME = 180000000; // in Milliseconds
  static COOKIE_NAMES = {
    PREVIOUS_PAGE: "__COACH_PREVIOUS_PAGE",
  };
  static currentHistory;
  static setCurrentHistoryObj(history) {
    this.currentHistory = history;
  }
  static getCurrentHistoryObj() {
    return this.currentHistory;
  }
  static isOverlayExceptionUrl() {
    const currentPathname = window.location.pathname;
    let isVisible = false;
    this.OVERLAY_EXCEPTION_URL.forEach((url) => {
      if (currentPathname.indexOf(url) < 0) {
        isVisible = true;
      }
    });
    return isVisible;
  }
  static isBottomNavigationBarVisible() {
    const currentPathname = window.location.pathname;
    let isVisible = false;
    this.BOTTOM_NAVIGATION_APPROVED_URLS.forEach((url) => {
      if (currentPathname.indexOf(url) > -1) {
        isVisible = true;
      }
    });
    return isVisible;
  }
  static isFeedbackSurveyPopUpVisible() {
    const currentPathname = window.location.pathname;
    let isVisible = false;
    this.FEEDBACK_SURVEY_POPUP_APPROVED_URLS.forEach((url) => {
      if (currentPathname.indexOf(url) > -1) {
        isVisible = true;
      }
    });
    return isVisible;
  }
  static getServerMonthValue(value) {
    this.MONTH_DATE[value];
    return this.MONTH_DATE[value];
  }
  static getMonthObjectFromValue(value) {
    let monthObj;
    this.MONTH_DATE.forEach((month, index) => {
      if (month.monthValue == value) {
        monthObj = month;
      }
    });
    return monthObj;
  }
  static getMonthLastDateFromMonthValue(value) {
    let lastDate;
    this.MONTH_DATE.forEach((month, index) => {
      if (month.monthValue == value) {
        lastDate = month.lastDate;
      }
    });
    return Number(lastDate);
  }
  static toggleHeaderLoader(isLoading = true, isSection = false) {
    window.togglePreloader(isLoading, isSection);
  }
  static truncateDecimals(number, digits = 2) {
    let finalNumber = number ? number.toString() : "0";
    if (finalNumber) {
      const finalNumberParts = finalNumber.split(".");
      if (finalNumberParts && finalNumberParts.length > 1) {
        if (finalNumberParts[1].length > 1) {
          const with2Decimals = finalNumber.match(/^-?\d+(?:\.\d{0,2})?/)[0];
          finalNumber = with2Decimals;
        } else {
          finalNumber = finalNumberParts.join(".") + "0";
        }
      } else {
        finalNumber = finalNumber + ".00";
      }
    }
    return finalNumber;
  }
  static currencyNomenclature(number) {
    let finalNumber = this.truncateDecimals(number);
    const finalNumberParts = finalNumber.split(".");
    return finalNumberParts[0] + " dollars &" + finalNumberParts[1] + " cents";
  }
  static isValidPhone(phone) {
    let isValidPhone = true;
    if (phone && (phone.length != 10 || isNaN(phone))) {
      isValidPhone = false;
    }

    return isValidPhone;
  }
  static isValidEmail(email) {
    let isValid = true;
    // eslint-disable-next-line
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (reg.test(email) === false) {
      isValid = false;
    }
    return isValid;
  }
  static isValidPassword(password) {
    let isValid = true;
    // eslint-disable-next-line
    const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z\d;:.,"'=~\-+^#$\s@!%*?&]{8}/;

    if (reg.test(password) === false) {
      isValid = false;
    }
    return isValid;
  }
  static isValidName(name) {
    let isValid = true;
    const reg = /[#$%^*()]/;
    const nameRegex = /^[A-Za-z0-9\s]*$/;
    if (name && name.trim()) {
      reg.test(name) === true ? (isValid = false) : "";
      nameRegex.test(name) == true ? (isValid = true) : (isValid = false);
    } else {
      isValid = false;
    }
    return isValid;
  }
  static isAndroid() {
    let isAndroid = false;
    if (window.navigator.appVersion.indexOf("Android") >= 0) {
      isAndroid = true;
    }
    return isAndroid;
  }
  static isIos() {
    let isIos = false;
    if (
      window.navigator.appVersion.indexOf("iPhone") >= 0 ||
      window.navigator.appVersion.indexOf("iPad") >= 0
    ) {
      isIos = true;
    }
    return isIos;
  }
  static isAndroidWebView() {
    let isAndroidWebView = false;
    if (this.isAndroid() && window.navigator.userAgent.indexOf(" wv") >= 0) {
      isAndroidWebView = true;
    }
    return isAndroidWebView;
  }
  static isChrome() {
    let isChromeBrowser = false;
    if (
      (window.chrome && window.chrome.csi) ||
      window.navigator.userAgent.toLowerCase().indexOf("crios") >= 0 ||
      window.navigator.userAgent.toLowerCase().indexOf("chrome") >= 0
    ) {
      isChromeBrowser = true;
    }
    return isChromeBrowser;
  }
  static isSafari() {
    let isSafariBrowser = false;
    if (window.navigator.userAgent.toLowerCase().indexOf("safari") >= 0) {
      isSafariBrowser = true;
    }
    return isSafariBrowser;
  }
  static getRawBarcode(phrase) {
    const stringArray = phrase.split(" ");
    return stringArray.join("");
  }
  static getRawPhoneNumber(phrase) {
    let phone = phrase.replace(" ", "");
    phone = phone.replace("(", "");
    phone = phone.replace(")", "");
    phone = phone.replace("-", "");
    return phone;
  }
  static encodeBase64(input) {
    return base64.encode(encodeURIComponent(input));
  }
  static decodebase64(encodedInput) {
    return decodeURIComponent(base64.decode(encodedInput));
  }
  static addToCart(productObj) {
    let cartProductsArray = [];
    cartProductsArray = this.getCart();
    cartProductsArray.push(productObj);
    localStorage.setItem(
      this.CART,
      this.encodeBase64(JSON.stringify(cartProductsArray))
    );
    return cartProductsArray;
  }
  static setCart(cartProductsArray) {
    localStorage.setItem(
      this.CART,
      this.encodeBase64(JSON.stringify(cartProductsArray))
    );
    return cartProductsArray;
  }
  static clearCart() {
    localStorage.removeItem(this.CART);
  }
  static removeFromCart(index) {
    const cart = this.getCart();
    // cart.forEach((item, index) => {
    //     if (item && item.itemId === productItemId) {
    //         cart.splice(index, 1);
    //     }
    // });
    cart.splice(index, 1);
    this.setCart(cart);
    return cart;
  }
  static getCart() {
    let cartProductsArray = [];
    const cartProductsString = localStorage.getItem(this.CART);
    if (cartProductsString && cartProductsString.trim()) {
      cartProductsArray = JSON.parse(this.decodebase64(cartProductsString));
    }
    return cartProductsArray;
  }
  static getStoreDetails() {
    const storeDetails = Util.getObjectFromStorage(this.STORE_DETAILS);
    if (storeDetails && storeDetails.storeId) {
      return storeDetails;
    } else {
      window.location.href = "/";
    }
  }
  static clearStorePin() {
    Util.removeStringFromStorage(this.STORE_PIN);
    return true;
  }
  static setStorePin(pin) {
    Util.addStringToStorage(this.STORE_PIN, pin);
    return pin;
  }
  static getStorePin() {
    const storePin = Util.getStringFromStorage(this.STORE_PIN);
    return storePin;
  }
  static beautifyUrl(urlString, params) {
    for (let value of params) {
      urlString = urlString.replace("{?}", value);
    }
    return urlString;
  }
  static addObjectToStorage(key, value) {
    const encryptedValue = this.encodeBase64(JSON.stringify(value));
    localStorage.setItem(key, encryptedValue);
  }
  static getObjectFromStorage(key) {
    const encryptedValue = localStorage.getItem(key) || "";
    if (encryptedValue) {
      return JSON.parse(this.decodebase64(encryptedValue));
    } else {
      return;
    }
  }
  static addStringToSessionStorage(key, value) {
    const encryptedValue = this.encodeBase64(value);
    sessionStorage.setItem(key, encryptedValue);
  }
  static getStringFromSessionStorage(key) {
    const encryptedValue = sessionStorage.getItem(key);
    if (encryptedValue) {
      return this.decodebase64(encryptedValue);
    } else {
      return;
    }
  }
  static addObjectToSessionStorage(key, value) {
    const encryptedValue = this.encodeBase64(JSON.stringify(value));
    sessionStorage.setItem(key, encryptedValue);
  }
  static getObjectFromSessionStorage(key) {
    const encryptedValue = sessionStorage.getItem(key) || "";
    if (encryptedValue) {
      return JSON.parse(this.decodebase64(encryptedValue));
    } else {
      return;
    }
  }
  static removeFromSessionStorage(key) {
    sessionStorage.removeItem(key);
  }
  static removeStringFromStorage(key) {
    localStorage.removeItem(key);
  }
  static addStringToStorage(key, value) {
    const encryptedValue = this.encodeBase64(value);
    localStorage.setItem(key, encryptedValue);
  }
  static getStringFromStorage(key) {
    const encryptedValue = localStorage.getItem(key);
    if (encryptedValue) {
      return this.decodebase64(encryptedValue);
    } else {
      return;
    }
  }
  static clearToken() {
    this.removeStringFromStorage(this.STORE_TOKEN);
  }
  static setToken(token) {
    this.addStringToStorage(this.STORE_TOKEN, token);
  }
  static getToken() {
    return this.getStringFromStorage(this.STORE_TOKEN);
  }
  static clearSFCCSession() {
    const needToRemember =
      Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";
    this.clearSFCCToken();
    const additionalAppliedCoupon = Util.getStringFromStorage(
      Util.ADDITIONAL_APPLIED_COUPON
    );
    const additionalAutoAppliedCoupon = Util.getObjectFromStorage(
      Util.ADDITIONAL_AUTO_APPLIED_COUPON
    );
    const signupPromo = Util.getObjectFromSessionStorage(Util.SIGNUP_PROMO);
    if (
      additionalAppliedCoupon &&
      signupPromo &&
      signupPromo.couponId &&
      additionalAppliedCoupon == signupPromo.couponId
    ) {
      Util.removeStringFromStorage(Util.ADDITIONAL_APPLIED_COUPON);
    }
    if (
      additionalAutoAppliedCoupon &&
      additionalAutoAppliedCoupon.couponId &&
      signupPromo &&
      signupPromo.couponId &&
      additionalAutoAppliedCoupon.couponId == signupPromo.couponId
    ) {
      Util.removeStringFromStorage(Util.ADDITIONAL_AUTO_APPLIED_COUPON);
    }

    if (!needToRemember || needToRemember == "false") {
      Util.clearLoggedInUserDetails();
    }
  }
  static clearSFCCToken() {
    this.removeStringFromStorage(this.SFCC_TOKEN);
    this.removeFromSessionStorage(this.SFCC_TOKEN);
    Util.removeFromSessionStorage(Util.PERSONALIZED_PROMO_LIST);
    Util.removeFromSessionStorage(Util.USER_CUSTOM_ATTRIBUTE);
  }
  static setSFCCToken(
    token,
    expiry = Date.now() + Util.SFCC_TOKEN_EXPIRY_TIME,
    needToRemember = false
  ) {
    const createdTime = Date.now();
    const tokenObj = {
      token: token,
      updatedTime: createdTime,
      expiryTime: expiry,
    };
    if (needToRemember && needToRemember.toString() == "true") {
      this.addObjectToStorage(this.SFCC_TOKEN, tokenObj);
    } else {
      this.addObjectToSessionStorage(this.SFCC_TOKEN, tokenObj);
    }
  }
  static getSFCCToken() {
    const needRememberUser =
      Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";
    let tokenObj;
    if (needRememberUser && needRememberUser == "true") {
      tokenObj = Util.getObjectFromStorage(Util.SFCC_TOKEN) || {};
    } else {
      tokenObj = Util.getObjectFromSessionStorage(Util.SFCC_TOKEN) || {};
    }
    return tokenObj && tokenObj.token ? tokenObj.token : "";
  }
  static getSFCCTokenObj() {
    const needRememberUser =
      Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";
    let tokenObj;
    if (needRememberUser && needRememberUser == "true") {
      tokenObj = Util.getObjectFromStorage(Util.SFCC_TOKEN) || {};
    } else {
      tokenObj = Util.getObjectFromSessionStorage(Util.SFCC_TOKEN) || {};
    }
    return tokenObj;
  }
  static clearCoupons() {
    localStorage.removeItem(this.APPLIED_COUPONS);
  }
  static isMobile() {
    return navigator.userAgent.match(
      /(iPad)|(iPhone)|(iPod)|(android)|(Android)|(webOS)/i
    );
  }
  static getGAFlowConfig() {
    let flowConfig = "no gate";
    const storeDetails = this.getStoreDetails();
    if (storeDetails) {
      if (
        storeDetails.emailposition == "PRE" &&
        storeDetails.pinValidationActivated == true
      ) {
        flowConfig = "gated|code and email";
      } else if (
        storeDetails.emailposition == "PRE" &&
        storeDetails.pinValidationActivated == false
      ) {
        flowConfig = "gated|email";
      } else if (
        storeDetails.emailposition == "INIT" &&
        storeDetails.pinValidationActivated == true
      ) {
        flowConfig = "gated|email and code";
      } else if (
        storeDetails.emailposition == "INIT" &&
        storeDetails.pinValidationActivated == false
      ) {
        flowConfig = "gated|email";
      } else if (
        storeDetails.emailposition == "POST" &&
        storeDetails.pinValidationActivated == true
      ) {
        flowConfig = "gated|code";
      } else if (
        storeDetails.emailposition == "NONE" &&
        storeDetails.pinValidationActivated == true
      ) {
        flowConfig = "gated|code";
      }
    } else {
      flowConfig = "";
    }
    return flowConfig;
  }
  static getSelectedLanguage() {
    let selectedLanguage =
      this.getStringFromStorage(Util.APP_LANGUAGE) || "en-us";
    selectedLanguage = selectedLanguage == "en" ? "en-us" : selectedLanguage;
    return selectedLanguage;
  }
  static getCustomAttributes() {
    let customAttribute = "";
    let userCustomAttribute =
      this.getObjectFromSessionStorage(this.USER_CUSTOM_ATTRIBUTE) || "";
    let channelValue,
      purchaseCountValue,
      priceSensitivityflag,
      ageRangeValue,
      tenureValue,
      optedInValue,
      statusValue;
    if (userCustomAttribute) {
      userCustomAttribute.forEach((attribute, index) => {
        if (attribute.key == "channel_segment") {
          channelValue = attribute.value || undefined;
        } else if (attribute.key == "purchase_count_segment") {
          purchaseCountValue = attribute.value || undefined;
        } else if (attribute.key == "cust_price_sensitivity_flag") {
          priceSensitivityflag = attribute.value || undefined;
        } else if (attribute.key == "age_range_segment") {
          ageRangeValue = attribute.value || undefined;
        } else if (attribute.key == "tenure_segment") {
          tenureValue = attribute.value || undefined;
        } else if (attribute.key == "opted_in_segment") {
          optedInValue = attribute.value || undefined;
        } else {
          statusValue = attribute.value || undefined;
        }
      });
      customAttribute =
        channelValue +
        "|" +
        priceSensitivityflag +
        "|" +
        ageRangeValue +
        "|" +
        tenureValue +
        "|" +
        statusValue +
        "|" +
        optedInValue +
        "|" +
        purchaseCountValue;
    } else {
      customAttribute =
        "undefined|undefined|undefined|undefined|undefined|undefined|undefined";
    }
    return customAttribute;
  }
  static getEmailId() {
    let userEmail = this.getStringFromStorage(this.USER_EMAIL) || "";
    const loggedInUserDetail = this.getLoggedInUserDetails();
    if (loggedInUserDetail && loggedInUserDetail.email) {
      const encEmail = sha256(loggedInUserDetail.email);
      userEmail = encEmail;
    }
    return userEmail;
  }
  static getCurrencyCode() {
    return "USD";
  }
  static getVariant(styleCode, colorCode, sizeCode) {
    return styleCode + (colorCode || "") + (sizeCode || "");
  }
  static getSelectedLanguageObject(selectedLanguageValue) {
    let languageObj = { value: "", label: "" };
    this.LOCALE_VALUES_LIST.forEach((obj, index) => {
      if (obj.value === selectedLanguageValue) {
        languageObj = obj;
      }
    });

    return languageObj;
  }
  static isElementInViewport(el) {
    var top = el.offsetTop;
    var left = el.offsetLeft;
    var width = el.offsetWidth;
    var height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top >= window.pageYOffset &&
      left >= window.pageXOffset &&
      top + height + 60 <= window.pageYOffset + window.innerHeight &&
      left + width <= window.pageXOffset + window.innerWidth
    );
  }
  static isCouponApplied(couponId) {
    const couponList = this.getObjectFromStorage(this.APPLIED_COUPONS) || [];
    let isApplied = false;
    if (couponList && couponList.length) {
      couponList.forEach((coupon) => {
        if (coupon.couponId) {
          if (coupon.couponId == couponId) {
            isApplied = true;
          }
        } else {
          if (coupon == couponId) {
            isApplied = true;
          }
        }
      });
    }
    return isApplied;
  }
  static getCustomSizeImage(imgUrl, size, isThumb = false) {
    let url = imgUrl;
    if (imgUrl && size) {
      if (isThumb) {
        url = url.replace("$thumbnail$", "wid=" + size);
      } else {
        url = url + "?wid=" + size;
      }
    }

    return url;
  }
  static isScrolledIntoView(el) {
    let isVisible = false;
    if (el) {
      const rect = el.getBoundingClientRect();
      const elemTop = rect.top;
      const elemBottom = rect.bottom;
      isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
    }
    return isVisible;
  }
  static setColorSwatchFlag(isSet) {
    const encryptedValue = this.encodeBase64(isSet);
    sessionStorage.setItem(this.COLOR_SWATCH_FLAG, encryptedValue);
    return isSet;
  }
  static getColorSwatchFlag() {
    const encryptedValue = sessionStorage.getItem(this.COLOR_SWATCH_FLAG);
    if (encryptedValue) {
      return this.decodebase64(encryptedValue);
    } else {
      return;
    }
  }
  static resetColorSwatchFlag() {
    sessionStorage.removeItem(this.COLOR_SWATCH_FLAG);
  }
  static scrollTop() {
    window.scrollTo(0, 0);
  }
  static getMobileOSVersion() {
    let version;
    const userAgentString = window.navigator.userAgent;
    const matchedString = userAgentString.match(/(OS\s)(\d+)/gm);
    if (matchedString && matchedString.length) {
      version = matchedString[0].split(" ")[1];
    }

    return version;
  }
  static gotoRoute(path, history) {
    // const iosVersion = this.getMobileOSVersion();
    // if (iosVersion == 11) {
    //     window.location.href = path;
    // } else {
    //     if (path && history) {
    //         history.push(path);
    //     }
    // }
    if (path && history) {
      history.push(path);
    }
  }
  static parseLinkContainingString(stringValue = "") {
    const result = {
      link: "",
      label: "",
    };
    if (stringValue) {
      var matches = [];
      // EXPECTATION EXACTLY LIKE <a href="<link">Label</a>
      stringValue.replace(
        /[^<]*(<a href="([^"]+)">([^<]+)<\/a>)/g,
        function () {
          matches.push(Array.prototype.slice.call(arguments, 1, 4));
        }
      );
      if (matches && matches.length) {
        result.link = matches[1];
        result.label = matches[2];
      }
    }
    return result;
  }
  static isUserLoggedIn() {
    /*
            This function will return 3 logged in status YES, PARTIAL, NO
        */
    let isLoggedIn = this.LOGGEDIN_STATUS.LOGGED_OUT;
    const userDetails = Util.getLoggedInUserDetails();
    const sfccToken = Util.getSFCCToken();
    if (userDetails && userDetails.customer_id && sfccToken) {
      isLoggedIn = this.LOGGEDIN_STATUS.LOGGED_IN;
    } else if (userDetails && userDetails.customer_id && !sfccToken) {
      isLoggedIn = this.LOGGEDIN_STATUS.PARTIALLY_LOGGED_IN;
    }

    return isLoggedIn;
  }
  static updateLoggedInUserDetails(data) {
    const needToRemember =
      Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";
    if (needToRemember && needToRemember == "true") {
      Util.addStringToStorage(Util.NEED_REMEMBER_USER, "true");
      Util.addObjectToStorage(Util.LOGGEDIN_USER_DETAILS, data);
    } else {
      Util.addObjectToSessionStorage(Util.LOGGEDIN_USER_DETAILS, data);
    }
  }
  static setLoggedInUserDetails(data, needToRemember = false) {
    const excludeProperties = [
      "auth_type",
      "creation_date",
      "last_login_time",
      "last_modified",
      "last_visit_time",
      "previous_login_time",
      "previous_visit_time",
      "c_directMailOptIn",
      "c_emailOptIn",
      "c_homePhoneOptIn",
      "c_marketingSource",
      "c_messageType",
      "c_mobilePhoneOptIn",
      "c_officePhoneOptIn",
      "c_smsOptIn",
      "c_sourceCode",
    ];
    excludeProperties.forEach((property) => {
      delete data[property];
    });
    if (needToRemember && needToRemember.toString() == "true") {
      Util.addStringToStorage(Util.NEED_REMEMBER_USER, "true");
      Util.addObjectToStorage(Util.LOGGEDIN_USER_DETAILS, data);
    } else {
      Util.addObjectToSessionStorage(Util.LOGGEDIN_USER_DETAILS, data);
    }
  }
  static clearLoggedInUserDetails() {
    Util.removeFromSessionStorage(Util.LOGGEDIN_USER_DETAILS);
    Util.removeStringFromStorage(Util.LOGGEDIN_USER_DETAILS);
    Util.removeStringFromStorage(Util.NEED_REMEMBER_USER);
    Util.removeStringFromStorage(Util.DATALAB_CUSTOMER_ID);
  }
  static clearLoggedInUserDetailsKeepEmailAndFlag() {
    const userDetails = Util.getLoggedInUserDetails();
    Util.removeStringFromStorage(Util.LOGGEDIN_USER_DETAILS);
    Util.removeStringFromStorage(Util.DATALAB_CUSTOMER_ID);
    Util.setLoggedInUserDetails({ email: userDetails.email }, "true");
    // Util.removeStringFromStorage(Util.NEED_REMEMBER_USER);
  }
  static getLoggedInUserDetails() {
    let userDetails = {};
    const needRememberUser =
      Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";

    if (needRememberUser && needRememberUser == "true") {
      userDetails = Util.getObjectFromStorage(Util.LOGGEDIN_USER_DETAILS);
    } else {
      userDetails = Util.getObjectFromSessionStorage(
        Util.LOGGEDIN_USER_DETAILS
      );
    }

    return userDetails;
  }
  static minToSeconds(min, type = "seconds") {
    let convertedValue;
    if (min && Number(min)) {
      if (type === "seconds") {
        convertedValue = Number(min) * 60;
      } else if (type == "milliseconds") {
        convertedValue = Number(min) * 60 * 1000;
      }
    }
    return convertedValue;
  }
  static getLoginHeader(login, password) {
    return "Basic " + base64.encode(login + ":" + password);
  }
  static isSFCCTokenExpired() {
    let isExpired = false;
    const sfccTokenObj = Util.getSFCCTokenObj();
    const storeDetail = Util.getStoreDetails();
    const tokenTimeout =
      storeDetail &&
      storeDetail.config &&
      storeDetail.config.sfccTokenTimeout &&
      Number(storeDetail.config.sfccTokenTimeout)
        ? Util.minToSeconds(storeDetail.config.sfccTokenTimeout, "milliseconds")
        : 0;

    if (
      tokenTimeout &&
      sfccTokenObj &&
      sfccTokenObj.token &&
      sfccTokenObj.updatedTime
    ) {
      isExpired =
        Number(sfccTokenObj.updatedTime) + tokenTimeout <= Date.now()
          ? true
          : false;
    }

    return isExpired;
  }
  static setCookie(name, value, days = 1) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  static getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }
  static clearCookie(name) {
    document.cookie = name + "=; Max-Age=-99999999;";
  }
  static clearAllCookie() {
    for (var cookieName in Util.COOKIE_NAMES) {
      if (Util.COOKIE_NAMES.hasOwnProperty(cookieName)) {
        this.clearCookie(Util.COOKIE_NAMES[cookieName]);
      }
    }
  }
  static getQueryParams() {
    const searchString = window.location.search;
    let params;
    if (searchString && searchString.split("&").length > 0) {
      params = {};
      const normalizedString = searchString.substring(1);
      const queryParts = normalizedString.split("&");
      queryParts.forEach((part) => {
        const key = part.split("=")[0];
        const value = part.split("=")[1];
        params[key] = value;
      });
    }

    return params;
  }
  static getDecodedSFCCTokenObject(token) {
    var decodedObj;
    if (token) {
      const normalizeTokenValue = token.replace("Bearer ", "");
      decodedObj = base64.decode(normalizeTokenValue);
    }

    return decodedObj;
  }
  static parseJWTToken(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  static getLocalWishlist() {
    return this.getObjectFromStorage(this.LOCAL_WISHLIST) || [];
  }
  static clearLocalWishlist() {
    this.removeStringFromStorage(this.LOCAL_WISHLIST);
  }
  static setLocalWishlist(list = []) {
    this.addObjectToStorage(this.LOCAL_WISHLIST, list);
    return list;
  }
  static addToLocalWishlist(itemDetails) {
    const localWishlist = this.getLocalWishlist() || [];
    if (itemDetails && itemDetails.productId) {
      localWishlist.push(itemDetails);
    }
    this.addObjectToStorage(this.LOCAL_WISHLIST, localWishlist);
    return localWishlist;
  }
  static removeFromLocalWishlist(productId) {
    const localWishlist = this.getLocalWishlist() || [];
    if (productId) {
      let removeIndex;
      localWishlist.forEach((wishlist, index) => {
        if (wishlist && wishlist.productId && wishlist.productId == productId) {
          removeIndex = index;
        }
      });
      if (removeIndex >= 0) {
        localWishlist.splice(removeIndex, 1);
      }
      this.setLocalWishlist(localWishlist);
    }
    return localWishlist;
  }
  static getNormalizedLocalWishlist(remoteList) {
    let localWishlist = Util.getLocalWishlist() || [];
    localWishlist = localWishlist.reverse();
    if (remoteList && remoteList.length) {
      remoteList.forEach((remoteItem, remoteIndex) => {
        localWishlist.forEach((localItem, localIndex) => {
          if (localItem.productId == remoteItem.productId) {
            localWishlist.splice(localIndex, 1);
          }
        });
      });
    }
    return localWishlist;
  }
  static getIndexFromAttrib(list, attrib, value) {
    let valueIndex = -1;
    if (list && attrib && value && list.length) {
      list.forEach((item, index) => {
        if (item[attrib] == value) {
          valueIndex = index;
        }
      });
    }
    return valueIndex;
  }
  static getNormalizedLocalWishlistCount(remoteList) {
    const localWishlist = this.getNormalizedLocalWishlist(remoteList);
    return localWishlist.length;
  }
  static getAddToWishlistRequest(item, wishlistId = "") {
    let request;
    if (item && item.productId) {
      request = {
        type: "product",
        product_id: item.productId,
        upc: item.itemId,
        priority: 4,
        quantity: 1,
        public: true,
        wishListId: wishlistId || "",
      };
    }
    return request;
  }
  static isProductInLocalWishlist(upc) {
    const localWishlist = Util.getLocalWishlist();
    let product;
    if (upc && localWishlist && localWishlist.length) {
      localWishlist.forEach((wishlistItem) => {
        if (wishlistItem.itemId == upc) {
          product = wishlistItem;
        }
      });
    }
    return product;
  }

  // returns 'mm/dd/yyyy'
  static getClientDate() {
    const currentDate = new Date();
    return (
      currentDate.getMonth() +
      1 +
      "/" +
      currentDate.getDate() +
      "/" +
      currentDate.getFullYear()
    );
  }
  static isSUCCoupon(couponCode) {
    let isSUCCoupon = false;
    if (couponCode) {
      var letters = /[a-zA-Z]+/gm;
      if (couponCode.match(letters) && couponCode.match(letters).length > 0) {
        isSUCCoupon = true;
      }
    }
    return isSUCCoupon;
  }
  static dataLayerScroll(path, percentage) {
    dataLayer.push({
      event: "scrollTracking",
      scrollType: "manual",
      attributes: {
        distance: percentage + "%",
        label: path,
      },
    });
  }
  static isARequestedProduct(itemId) {
    const requestedProducts =
      Util.getObjectFromSessionStorage(Util.INLINE_REQUESTED_PRODUCT_IDS) || [];
    return requestedProducts.includes(itemId);
  }
  static getImageUrlWithCustomDimention(url, height, width) {
    let customUrl = "";
    if (url && width && height) {
      customUrl = url + `?wid=${width}&hei=${height}'`;
    }
    return customUrl;
  }
  static getFullImageUrls(imagePath, isArray = true) {
    let fullImageUrls = [];
    const imageDomain = "https://img1.cohimg.net/is/image/";
    if (isArray) {
      if (imagePath && imagePath.length) {
        imagePath.forEach((imageRelativePath) => {
          if (
            imageRelativePath.indexOf("http://") < 0 &&
            imageRelativePath.indexOf("https://") < 0
          ) {
            fullImageUrls.push(imageDomain + imageRelativePath);
          } else {
            fullImageUrls.push(imageRelativePath);
          }
        });
        // fullImageUrls = fullImageUrls.sort((a, b) => a.localeCompare(b));
      }
    } else {
      if (
        imagePath.indexOf("http://") < 0 &&
        imagePath.indexOf("https://") < 0
      ) {
        fullImageUrls = imageDomain + imagePath;
      } else {
        fullImageUrls = imagePath;
      }
    }
    return fullImageUrls;
  }

  static loadScript(url = "") {
    const scriptTag = document.createElement("script");
    scriptTag.async = true;
    scriptTag.src = url;
    scriptTag.type = "text/javascript";
    document.body.appendChild(scriptTag);
  }

  static removeScript(scriptToremove) {
    let allsuspects = document.getElementsByTagName("script");
    for (let i = allsuspects.length; i >= 0; i--) {
      console.log({ allsuspects });
      if (
        allsuspects[i] &&
        allsuspects[i].getAttribute("src") !== null &&
        allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1
      ) {
        console.log("found required node");
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  }

  static isECatalogueFlow() {
    const isECatalogueFlow = Util.getStringFromSessionStorage(
      this.IS_ECATALOGUE_FLOW
    );
    return isECatalogueFlow === "true";
  }

  static getInsiderGateGroup() {
    return Util.getStringFromStorage(Util.INSIDER_GATE_GROUP) || "";
  }

  static isSignupSessionLoggedOut() {
    return Util.getStringFromStorage(Util.IS_SIGNUP_SESSION_LOGGED_OUT) || "";
  }

  static openNewTab(url = "") {
    window.open(url, "_blank");
  }
}
