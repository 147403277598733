import OffersService from "../services/offers.service";
import Util from "../utils/util";
import configureStore from '../store/configureStore';
const store = configureStore();

export const setOffers = (storeId, request) => {
  return (dispatch, getState) => {
    return OffersService.getOffers(storeId, request)
      .then(data => {
        if (data) {
          Util.clearAllCookie();
          dispatch({
            type: "SET_OFFERS",
            todaysOffers: data.carouselTranslationMap,
            myOffers: data.couponPromoTranslationMap
          });
          const currentTime = new Date().getTime();
          Util.addStringToStorage(Util.TIMEOUT_TIMESTAMP, currentTime);
          
          // if (Util.isSFCCTokenExpired()) {
          //   Util.clearSFCCSession();
          // }
        }
        return data;
      })
      .catch(e => {
        console.log(e);
        window.togglePreloader(true);
        window.location.href = "/error";
      });
  };
};