import ProductService from "../services/product.service";
import Util from "../utils/util";

export const setCurrentProduct = (storeId, itemId, sectionPreloader = false) => {
  return (dispatch, getState) => {
    return ProductService.getProductDetails(storeId, itemId, sectionPreloader)
      .then(data => {
        if (data && data.itemId) {
          // Filtering Swatch Images
          if (data.imageURL && data.imageURL.length) {
            data.imageURL = data.imageURL.filter(url => {
              return !url.endsWith("swatch");
            });
          }
          ///////////////////////
          dispatch({
            type: "SET_PRODUCT_INFO",
            itemDetails: data
          });
          // TODO: ONLINE INVENTORY LOOKUP CALL
          const onlineInventoryRequest = {
            items: [{
              itemId: data.itemId || '',
              productId: data.productId || ''
            }]
          };
          dispatch(setOnlineInventory(onlineInventoryRequest, sectionPreloader));
        } 
        // else {
        //   dataLayer.push({
        //     errorValue: "No products available for this product id",
        //     event: "errorLogged",
        //     eventCallback: () => {
        //       // window.toggleErrorScanWindow();
        //       window.toggleInvalidItemErrorToast();
        //     },
        //     eventTimeout: 2000
        //   });
        // }
        return data;
      })
      .catch(e => {
        dataLayer.push({
          errorValue: "Request to fetch product details failed",
          event: "errorLogged",
          eventCallback: () => {
            // // window.toggleErrorScanWindow();
            // window.toggleInvalidItemErrorToast();
          },
          eventTimeout: 2000
        });
      });
  };
};

export const setCurrentProductPricing = (storeId, itemId, request, sectionPreloader = false) => {
  return (dispatch, getState) => {
    return ProductService.getProductPricing(storeId, itemId, request, sectionPreloader)
      .then(data => {
        if (data && data.itemId) {
          dispatch({
            type: "SET_PRODUCT_PRICING",
            itemPriceInfo: data
          });
        } else {
          // window.toggleErrorScanWindow();
          window.toggleInvalidItemErrorToast();
        }
        return data;
      })
      .catch(e => {
        console.log(e);
        // window.toggleErrorScanWindow();
        window.toggleInvalidItemErrorToast();
      });
  };
};
const setOnlineInventory = (onlineInventoryRequest, sectionPreloader = false) => {
  return (dispatch, getState) => {
    ProductService.getOnlineInventory(onlineInventoryRequest, sectionPreloader)
      .then(onlineInventroyData => {
        if (onlineInventroyData && onlineInventroyData.payload && onlineInventroyData.payload.itemIds && onlineInventroyData.payload.itemIds.length) {
          dispatch({
            type: "SET_ONLINE_PRODUCTS_INVENTORY",
            onlineItemsInventory: onlineInventroyData.payload.itemIds
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
  };
};
const setInventory = (storeId, itemIdsArray, dispatch, sectionPreloader = false) => {
    let storeDetails = Util.getStoreDetails()
    let country = storeDetails.countryCode === "USA"?"US":"CA"
    const request = {
      "GetInventoryRequest": [{
        "OutputStoreDetail": "False",
        "ProductIDs": itemIdsArray,
        "StoreNumber": [storeId.toString()],
        "SearchRadius": "",
        "SearchPostalCode":"",
        "clientZone": storeDetails.fMSSiteId +"_COACH_" + country
      }]
    }
    ProductService.getInventory(request, sectionPreloader)
      .then(storeInventoryData => {
        const availableItems = [];
        const otherStoreAvailableItems = [];
        const outOfStockItems = [];
        if (storeInventoryData && storeInventoryData.payload && storeInventoryData.payload.Inventory && storeInventoryData.payload.Inventory.length) {          
          storeInventoryData.payload.Inventory.forEach(item => {
            let availableToPromise = Number(item.StoreQuantity);
            let otherStoreAvailableToPromise = Number(item.AllStoresQuantity) - Number(item.ReservedQuantity) - Number(item.ThresholdQuantity);
            if (availableToPromise > 0) {
              availableItems.push(item.ProductID);
            } else if (otherStoreAvailableToPromise > 0) {
              availableItems.push(item.ProductID);
              otherStoreAvailableItems.push(item.ProductID);
            } else {
              outOfStockItems.push(item.ProductID);
            }
          });
        }
        dispatch({
          type: "SET_SIMILAR_PRODUCTS_INVENTORY",
          similarItemsInventory: availableItems
        });
        dispatch({
          type: "SET_SIMILAR_PRODUCTS_OTHER_STORE_INVENTORY",
          similarItemsOtherStoreInventory: otherStoreAvailableItems
        });
        // dispatch({
        //   type: "SET_SIMILAR_PRODUCTS_SOLDOUT_INVENTORY",
        //   similarItemsSoldOutInventory: outOfStockItems
        // });
      })
      .catch(e => {
        console.log(e);
        dispatch({
          type: "SET_SIMILAR_PRODUCTS_INVENTORY",
          similarItemsInventory: []
        });
        dispatch({
          type: "SET_SIMILAR_PRODUCTS_OTHER_STORE_INVENTORY",
          similarItemsOtherStoreInventory: []
        });
        dispatch({
          type: "SET_SIMILAR_PRODUCTS_SOLDOUT_INVENTORY",
          similarItemsSoldOutInventory: []
        });
      });
};

export const setSimilarItems = (storeId, itemId, sectionPreloader = false) => {
  return (dispatch, getState) => {
    ProductService.getSimilarItems(storeId, itemId, sectionPreloader)
      .then(data => {
        if (data && data.itemList) {
          data.itemList.push(itemId);
        } else {
          data = data || {};
          data.itemList = [itemId];
        }
        if (data && data.itemList) {
          dispatch({
            type: "SET_SIMILAR_PRODUCTS",
            similarProducts: data,
            selectedSize: data.selectedSize,
            selectedColor: data.selectedColor
          });
          // Adding loaded product for inventory check
          setInventory(storeId , data.itemList, dispatch, sectionPreloader);
        } else {
          dispatch({
            type: "SET_SIMILAR_PRODUCTS_INVENTORY",
            similarItemsInventory: []
          });
          dispatch({
            type: "SET_SIMILAR_PRODUCTS_OTHER_STORE_INVENTORY",
            similarItemsOtherStoreInventory: []
          });
          dispatch({
            type: "SET_SIMILAR_PRODUCTS_SOLDOUT_INVENTORY",
            similarItemsSoldOutInventory: []
          });
          dispatch({
            type: "SET_SIMILAR_PRODUCTS",
            similarProducts: {},
            selectedSize: '',
            selectedColor: ''
          });
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
};

export const setRecommendedItems = (storeId, itemId, userAppliedCouponNum) => {
  return (dispatch, getState) => {
    const appliedCoupon = Util.getObjectFromStorage(Util.APPLIED_COUPONS) || [];
    const personalizedPromosList = Util.getObjectFromSessionStorage(Util.PERSONALIZED_PROMO_LIST) || [];
    const isLoggedIn =  (Util.isUserLoggedIn() == Util.LOGGEDIN_STATUS.LOGGED_IN).toString()
    let storeDetails = Util.getStoreDetails()
    let country = storeDetails.countryCode === "USA"?"US":"CA"
    const request = { 
      "upc_code": itemId, 
      "store_num": storeId,
      "token": "Bearer "+Util.getToken(),
      "couponNum": [],
      "userAppliedCouponNum": userAppliedCouponNum,
      "personalizedPromosList": [],
      "isLoggedIn": isLoggedIn,
      "clientZone": storeDetails.fMSSiteId +"_COACH_" + country
    };
    appliedCoupon.forEach(coupon => {
      if (coupon && coupon.couponId) {
        request.couponNum.push(coupon.couponId);
      }
    });
    request.personalizedPromosList = personalizedPromosList;
    ProductService.getRecommendedItems(request)
      .then(data => {
        if (data && data.payload && data.payload.productList && data.payload.productList.length) {
          dispatch({
            type: "SET_RECOMMENDED_PRODUCTS_INVENTORY",
            recommendedItemsInventory: data.payload || {}
          });
        }
      })
      .catch(e => {
        dispatch({
          type: "SET_RECOMMENDED_PRODUCTS_INVENTORY",
          recommendedItemsInventory: {}
        });
        console.log(e);
      });
  };
};

export const setColorSwatchSeletion = (itemId, oldItemId) => {
  return (dispatch, getState) => {
    const { similarItemsInventory, similarProducts, similarItemsSoldOutInventory } = getState().product;
    if (itemId && similarProducts) {
      let selectedColor = '';
      let selectedSize = '';
      if (similarProducts.sizeMap && Object.keys(similarProducts.sizeMap).length) {
        Object.keys(similarProducts.sizeMap).forEach((key) => {
          similarProducts.sizeMap[key].forEach((productItem) => {
            if (productItem.itemId == itemId) {
              productItem.selected = true;
              similarProducts.selectedColor = productItem.colorName;
              similarProducts.selectedSize = productItem.size;
            } else {
              productItem.selected = false;
            }
          });
        });
      }
      if (similarProducts.colorNameMap && Object.keys(similarProducts.colorNameMap).length) {
        Object.keys(similarProducts.colorNameMap).forEach((key) => {
          similarProducts.colorNameMap[key].forEach((productItem) => {
            if (productItem.itemId == itemId) {
              productItem.selected = true;
              similarProducts.selectedColor = productItem.colorName;
              similarProducts.selectedSize = productItem.size;
            } else {
              productItem.selected = false;
            }
          });
        });
      }
      const outOfStockIds = similarItemsSoldOutInventory || [];
      outOfStockIds.push(oldItemId);
      dispatch({
        type: "SET_SIMILAR_PRODUCTS_SOLDOUT_INVENTORY",
        similarItemsSoldOutInventory: outOfStockIds
      });
      // similarItemsInventory.push(oldItemId);
      dispatch({
        type: "SET_SIMILAR_PRODUCTS_INVENTORY",
        similarItemsInventory: similarItemsInventory
      });
      dispatch({
        type: "SET_SIMILAR_PRODUCTS",
        similarProducts: similarProducts,
        selectedSize: similarProducts.selectedSize,
        selectedColor: similarProducts.selectedColor
      });
    }
  };
};
export const requestProductToHold = (request) => {
  return (dispatch, getState) => {
    return ProductService.requestProductToHold(request)
      .then(holdProductResponse => {
        if (holdProductResponse) {

        }
        return holdProductResponse;
      })
      .catch(error => {
        throw error;
        console.log(error);
      });
  };
};
export const setProductListDetail = (productListDetail) => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_PRODUCT_LISTING",
      productListDetail: productListDetail
    });
  };
}
export const clearProductListDetail = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_PRODUCT_LISTING",
      productListDetail: {productList: [], totalRecords: 0}
    });
  };
}
export const getProductListing = (request, category) => {
  return (dispatch, getState) => {
    return ProductService.getProductListing(request, category)
      .then(productListingResponse => {
        const state = getState();
        if (productListingResponse && productListingResponse.status == "Success" && productListingResponse.payload && productListingResponse.payload.productList && productListingResponse.payload.productList.length) {
          let existingProductListDetail = state.product.productListDetail;
          Util.addStringToSessionStorage(Util.PRODUCT_LIST_SCROLL_ID, productListingResponse.payload.scrollId);
          if (request && request.scrollId && existingProductListDetail.productList && existingProductListDetail.productList.length) {
            existingProductListDetail.productList = existingProductListDetail.productList.concat(productListingResponse.payload.productList);
            existingProductListDetail.scrollId = productListingResponse.payload.scrollId;
          } else {
            existingProductListDetail = productListingResponse.payload;
          }
          dispatch(setProductListDetail(existingProductListDetail));
        } else if (productListingResponse && productListingResponse.status == "Success" && productListingResponse.payload && productListingResponse.payload.productList && productListingResponse.payload.productList.length <= 0 && !request.scrollId) {
          dispatch(setProductListDetail({productList: [], totalRecords: 0}));
        } else if (productListingResponse && productListingResponse.error && productListingResponse.error === 'Product detail list is empty') {
          Util.addStringToSessionStorage(Util.PRODUCT_LIST_SCROLL_ID, productListingResponse.payload.scrollId);
        }
        return productListingResponse;
      })
      .catch(error => {
        dispatch(setProductListDetail({productList: [], totalRecords: 0}));
        throw error;
      });
  };
};

export const inLineSettings = (request, storeId, sectionPreloader = false) => {
  return (dispatch, getState) => {
    return ProductService.inLineSettings(request, storeId, sectionPreloader)
    .then(settingsResponse => {
      if (settingsResponse) {
      
      }
      return settingsResponse;
    })
    .catch(error => {
      throw error;
      console.log(error);
    })
  }
}
export const categoryInventory = (request, storeId) => {
  return (dispatch, getState) => {
    return ProductService.categoryInventory(request, storeId)
    .then(categoryResponse => {
      if (categoryResponse && categoryResponse.payload) {
        dispatch({
          type: "SET_CATEGORY_PRODUCT_COUNT",
          categoryProductCount: categoryResponse.payload && categoryResponse.payload.results ? categoryResponse.payload.results : []
        });
      }
      return categoryResponse;
    })
    .catch(error => {
      console.log(error);
      throw error;
    })
  }
}

export const signupForAlerts = (request) => {
  return (dispatch, getState) => {
    return ProductService.signupForAlerts(request)
    .then(signUpResponse => {
      return signUpResponse;
    })
    .catch(error => {
      console.log(error);
      throw error;
    })
  }
}


