import React from 'react';
import Util from '../../utils/util';
import './language-selector-list.scss';
function LanguageSelectorList(props) {
    const selectedLanguage = Util.getSelectedLanguage() || 'en-us';
    return (
        <>
            {props.visible ?
                <div className="overlay-transperant" onClick={props.onClose}></div>:''
            }
            <div className={"language-selector-container-box "+(props.visible ? 'show':'hide')} tabIndex="1" hidden>
                <img src={props.closeIcon} onClick={props.onClose} alt="language selector container close" role="button" tabIndex="2" />
                <div className="heading"  tabIndex="-1">{props.localeCommon.selectLanguage}</div>
                <div className="list">
                    {Util.LOCALE_VALUES_LIST.map((language, index) => {
                        return (
                            <div tabIndex="1" className={"item "+(selectedLanguage == language.value ? 'selected' : '')} key={index} onClick={onChange.bind(this, language.value)}>
                                {language.label}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
    function onChange(value) {
        if (value) {
            const selectedObj = Util.getSelectedLanguageObject(value);
            if (selectedObj && selectedObj.value) {
                props.onLanguageChange(selectedObj);
            }
        }
    }
}

  
export default LanguageSelectorList;