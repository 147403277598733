const defaultOffers = {
    todaysOffers: [],
    myOffers: []
};

export default (state = defaultOffers, action) => {
  switch (action.type) {
    case "SET_OFFERS":
      return {
        ...state,
        todaysOffers: action.todaysOffers,
        myOffers: action.myOffers
      }
    default:
      return state;
  }
};
