import React from 'react';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import './barcode-input.scss';
class BarcodeInput extends React.Component {
    componentDidUpdate() {

    }
    render() {
        return (
            <>
                {/* <NumberFormat 
                    id="barcode-input" 
                    format="#   #####  #####  ##" 
                    type="text"
                    placeholder="2   94563  89020  9" 
                    className={"coach-barcode-input"+(this.props.className || '')} 
                    {...this.props} /> */}
                <InputMask className={"coach-barcode-input"+(this.props.className || '')} placeholder="2   94563  89020  9" {...this.props} mask="*  ***** ***** **" maskChar=" " formatChars={{
                    '*': '[A-Za-z0-9]'
                }}/>
            </>
        );
    }
}
  
export default BarcodeInput;