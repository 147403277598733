export const setManualCodeVisibility = (isVisible) => {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_MANUAL_CODE_VISIBILITY",
            isEnterProductCodeVisible: isVisible
        });
    };
};
export const setScanErrorVisibility = (isVisible) => {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_SCAN_ERROR_VISIBILITY",
            isScanError: isVisible
        });
    };
};
export const setToastVisibility = (isVisible) => {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_TOAST_VISIBILITY",
            toastVisible: isVisible
        });
    };
};
export const setToastMessage = (messsage) => {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_TOAST_MESSAGE",
            toastMessage: messsage
        });
    };
};