import React, { Component } from "react";
import { connect } from "react-redux";
import "./scan-permission-error-popup.scss";
import Util from "../../utils/util";
import BannerLogo from "../../components/logo/logo";
class ScanPermissionErrorPopUp extends Component {
  state = {
    isIos: false
  }
  componentDidMount() {
    window.togglePreloader();
    if (Util.isIos()) {
        this.setState({isIos: true});
    } else {
        this.setState({isIos: false});
    }
    dataLayer.push({
      'event': 'dataLayer-initialized',
      'customAttributes':Util.getCustomAttributes(),
      'userID': Util.getEmailId(),
      'language': Util.getSelectedLanguage(),
      'pageName': 'permission error',
      'pageType': 'error',
      'storeID': Util.getStoreDetails().storeId,
      'storeDesc': Util.getStoreDetails().name,
      'flowConfig': Util.getGAFlowConfig()
    });
    // Setting Camera permission error flag
    Util.addStringToStorage(Util.CAMERA_PERMISSION_FLAG, "true");
    
    window.togglePreloader(false);
  }
  render() {
    let errorCopyText = {};
    let common = {};
    if (this.props.locale && this.props.locale.screens) {
      errorCopyText = this.props.locale.screens.error;
      common = this.props.locale.screens.common;
    }
    const { isIos } = this.state;
    return (
        <div className="scan-permission-error-popup-container">
          <BannerLogo className="landing-logo" commonTextData={common}/>
          {isIos ?
            <>
              <span className="title">{errorCopyText.cameraPermissionErrorLine1}</span>
              <span className="title">{errorCopyText.cameraPermissionErrorLine2}</span>
              <span className="title">{errorCopyText.cameraPermissionErrorLine3}</span>
              <span className="title last">{errorCopyText.cameraPermissionErrorLine4}</span>
              <div className="list-container">
                <span className="item">{errorCopyText.cameraPermissionStepInfo}</span>
                <span className="item">{errorCopyText.cameraPermissioniOSStep1}</span>
                <span className="item">{errorCopyText.cameraPermissioniOSStep2}</span>
                <span className="item">{errorCopyText.cameraPermissioniOSStep3}</span>
                <span className="item">{errorCopyText.cameraPermissioniOSStep4}</span>
                <span className="item">{errorCopyText.cameraPermissioniOSStep5}</span>
              </div>
            </>:
            <>
              <span className="title">{errorCopyText.cameraPermissionErrorLine1}</span>
              <span className="title">{errorCopyText.cameraPermissionErrorLine2}</span>
              <span className="title">{errorCopyText.cameraPermissionErrorLine3}</span>
              <span className="title last">{errorCopyText.cameraPermissionErrorLine4}</span>
              <div className="list-container">
                <span className="item">{errorCopyText.cameraPermissionStepInfo}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep1}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep2}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep3}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep4}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep5}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep6}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep7}</span>
                <span className="item">{errorCopyText.cameraPermissionAndroidStep8}</span>
              </div>
            </>
          }
        </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    locale: state.locale
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

const connectedScanPermissionErrorPopUp = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScanPermissionErrorPopUp);

export default connectedScanPermissionErrorPopUp;