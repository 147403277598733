import Api from '../utils/api.service';
import ApiUrlConstant from '../utils/apiUrl.constant';
import Util from '../utils/util';
export default class ProductService {
    static getProductDetails(storeId, itemId, sectionPreloader = false) {
        let url = ApiUrlConstant.getApiUrl('itemInfo');
        url = Util.beautifyUrl(url, [storeId, itemId]);
        return Api.doGet(url, sectionPreloader);
    }
    static getProductPricing(storeId, itemId, request, sectionPreloader = false) {
        let url = ApiUrlConstant.getApiUrl('priceInfo');
        url = Util.beautifyUrl(url, [storeId, itemId]);
        return Api.doPost(url, request, true, {}, true, sectionPreloader);
    }
    static validateCoupon(storeId, request) {
        let url = ApiUrlConstant.getApiUrl('validateCoupon');
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request);
    }
    static getRecommendedItems(request) {
        let url = ApiUrlConstant.getApiUrl('recommendedItems', true);
        return Api.doPost(url, request, false);
    }
    static getSimilarItems(storeId, itemId, sectionPreloader = false) {
        let url = ApiUrlConstant.getApiUrl('similarItems');
        url = Util.beautifyUrl(url, [storeId, itemId]);
        return Api.doGet(url, sectionPreloader);
    }
    static getInventory(request, sectionPreloader = false) {
        let url = ApiUrlConstant.getApiUrl('inventory', true);
        return Api.doPost(url, request, true, {}, true, sectionPreloader);
    }
    static getOnlineInventory(request, sectionPreloader = false) {
        let url = ApiUrlConstant.getApiUrl('onlineInventory', true);
        return Api.doPost(url, request, true, {}, true, sectionPreloader);
    }
    static validateSUCCoupon(request) {
        let url = ApiUrlConstant.getApiUrl('validateSUCCoupon', true);
        return Api.doPost(url, request);
    }
    static requestProductToHold(request) {
        let url = ApiUrlConstant.getApiUrl('holdItem', true);
        return Api.doPost(url, request);
    }
    static inLineSettings(request, storeId, sectionPreloader) {
        let url = ApiUrlConstant.getApiUrl('inLineSettings', false);
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request, true, {}, true, sectionPreloader);
    }
    static getProductListing(request, category) {
        let url = ApiUrlConstant.getApiUrl('productListing', true);
        url = Util.beautifyUrl(url, [category]);
        return Api.doPost(url, request, false);
    }
    static categoryInventory(request, storeId) {
        let url = ApiUrlConstant.getApiUrl('categoryInventory', true);
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request);
    }
    static signupForAlerts(request) {
        let url = ApiUrlConstant.getApiUrl('signupAlerts', true);
        return Api.doPost(url, request, true, {}, true, true);
    }
}