import Util from "./util";

import "whatwg-fetch";
import ApiUrlConstant from "./apiUrl.constant";

const apiStack = [];
export default class Api {
  static checkTimeout() {
    const pathName = window.location.pathname;
    let isTimeout = false;
    if (pathName.indexOf('/store/') <= -1 && pathName.indexOf('/offers') <= -1 && pathName.indexOf('/home') <= -1) {
      const storeDetail = Util.getStoreDetails();
      if (storeDetail && storeDetail.config && storeDetail.config.needTimeout == "true") {
        const storeDetail = Util.getStoreDetails();
        let lastAccessTime = Number(Util.getStringFromStorage(Util.TIMEOUT_TIMESTAMP));
        let currentTime = new Date().getTime();
        if (lastAccessTime && currentTime) {
          const diff = currentTime - lastAccessTime;
          if (storeDetail && 
            storeDetail.config && 
            storeDetail.config.appTimeout && 
            Number(storeDetail.config.appTimeout) && 
            diff > Number(storeDetail.config.appTimeout)) {
              isTimeout = true;
              Util.removeStringFromStorage(Util.APPLIED_COUPONS);
              Util.clearCoupons();
              Util.clearCart();
              const isSubscribed = Util.getObjectFromStorage(Util.EMAIL_SUBSCRIPTION_FLAG);
              if (((storeDetail.emailposition == Util.POST_EMAIL_CAPTURE || 
                storeDetail.emailposition == 'NONE') &&
                !storeDetail.pinValidationActivated)) {
                  this.getToken('')
                    .then(data => {
                      Util.removeStringFromStorage(Util.BASKET_ID);
                      Util.removeStringFromStorage(Util.SERVER_DATE);
                      Util.removeStringFromStorage(Util.CAMERA_PERMISSION_FLAG);
                      Util.removeStringFromStorage(Util.APPLIED_COUPONS);
                      Util.removeStringFromStorage(Util.INTRO_CAROUSEL_SHOWN);
                      Util.clearCoupons();
                      Util.clearCart();
                      Util.addStringToStorage(Util.TIMEOUT_TIMESTAMP, currentTime);
                      window.location.href = '/home?timeout=true';
                    })
                    .catch(error => {
                      window.location.href = '/store/'+storeDetail.storeId+'?pinError=true';
                    });
              } else {
                window.location.href = '/store/'+storeDetail.storeId+'?timeout=true';
              }
          } else {
            lastAccessTime = currentTime;  
          }
        } else {
          lastAccessTime = currentTime;
        }
        Util.addStringToStorage(Util.TIMEOUT_TIMESTAMP, lastAccessTime);
        return isTimeout;
      }
    }
  }
  static refreshSFCCToken() {
    const sfccTokenObj = Util.getSFCCTokenObj();
    const storeDetail = Util.getStoreDetails();
    const tokenTimeout = storeDetail && 
      storeDetail.config && 
      storeDetail.config.sfccTokenTimeout && 
      Number(storeDetail.config.sfccTokenTimeout) ? Util.minToSeconds(storeDetail.config.sfccTokenTimeout, 'milliseconds') : 0;

    if (tokenTimeout && sfccTokenObj && sfccTokenObj.token && sfccTokenObj.updatedTime) {
      const needToRefresh = (Number(sfccTokenObj.updatedTime) + tokenTimeout) <= Date.now() ? true : false;
      if (needToRefresh) {
        const request = {
          type : 'refresh',
          countryCode:storeDetail.countryCode,
        };
        const url = ApiUrlConstant.getApiUrl('customerAuth' , true);
        return this.doPost(url, request, false, {}, false, false, false)
          .then(data => {
            if (data && data.token) {
              const rememberMe = Util.getStringFromStorage(Util.NEED_REMEMBER_USER) || "false";
              if (data.token && typeof data.token === 'object' && data.token.value) {
                Util.setSFCCToken(data.token.value, data.token.expiry, (rememberMe.toString() == "true" ? true : false));
              } else {
                Util.setSFCCToken(data.token, undefined, (rememberMe.toString() == "true" ? true : false));
              }
            } else if (data && data.fault && data.fault.type === "ExpiredTokenException") {
              Util.addStringToStorage(Util.IS_SIGNUP_SESSION_LOGGED_OUT, true);
              Util.clearSFCCSession();
            } else {
              Util.clearSFCCSession();
            }
          })
          .catch((e) => {
            console.log(e);
          });
      }
    }
  }
  static doGet(url, sectionPreloader = false, hidePreloader = false) {
    this.checkTimeout();
    const headers = {};
    if (url.indexOf('/storeinfo/') < 0) {
      const TOKEN = Util.getToken();
      if (TOKEN) {
        headers["Authorization"] = "Bearer "+TOKEN;
      } else {
        const calledApiDetail = {
          url: url,
          method: "doGet",
          request: {}
        };
        return this.getToken('', calledApiDetail);
      }
    }
    const conf = {
      method: "GET",
      headers: headers,
      mode: "cors",
      cache: "default"
    };
    Util.toggleHeaderLoader(true, sectionPreloader);
    apiStack.push(1);
    return fetch(url, conf)
      .then(resp => {
        if (url.indexOf('/storeinfo/') < 0) {
          this.refreshSFCCToken();
        }
        const errorResponse = {
          network_error: true,
          status_code: 500,
          status_message: "Something went wrong in network"
        };
        apiStack.pop();
        if (apiStack.length <= 0) {
          Util.toggleHeaderLoader(false);
        }
        if (resp.ok) {
          return resp.json();
        } else if (resp.status === 401) {
          // Google Analytics: Access token expired
          dataLayer.push({
            errorValue: "Unauthorized api call",
            event: "errorLogged"
          });
          errorResponse.status_code = 401;
          errorResponse.network_error = true;
          errorResponse.status_message = "Your session has expired";
          const calledApiDetail = {
            url: url,
            method: "doGet",
            request: payload
          };
          this.getToken('', calledApiDetail);
          return errorResponse;
        } else if (resp.status === 400) {
          errorResponse.status_code = 400;
          errorResponse.network_error = true;
          errorResponse.status_message = "Your session has expired";
          return errorResponse;
        } else {
          errorResponse.status_code = resp.status;
          errorResponse.network_error = true;
          errorResponse.status_message = "Somethig went wrong";
          throw errorResponse;
        }
      })
      .then(data => {
        if (url.indexOf('/proxy/sfcc') >= 0 && data && data.fault && data.fault.type && data.fault.type == 'ExpiredTokenException') {
          Util.clearSFCCSession();
          const history = Util.getCurrentHistoryObj();
          Util.gotoRoute('/account/login', history);
          Util.addStringToStorage(Util.IS_SIGNUP_SESSION_LOGGED_OUT, true);
          data.fault.message = 'Your session expired. Please login again!';
        }
        return data;
      })
      .catch(error => {
        console.log('API Error', error);
        let noNetworkString = '';
        if (error && error.name === 'TypeError' && Util.NO_NETWORK_LABEL.indexOf(error.message) >= 0) {
          noNetworkString = Util.NO_NETWORK_LABEL;
          // window.location.reload();
        } else {
          apiStack.pop();
          if (apiStack.length <= 0) {
            Util.toggleHeaderLoader(false);
          }
          const errorResponse = {
            network_error: true,
            status_code: noNetworkString,
            status_message: "Something went wrong in network"
          };
          throw errorResponse;
        }
      });
  }

  static doPost(url, payload, needPreloader = true, headers = {}, checkTimeout = true, sectionPreloader = false, sfccTokenRefresh = true) {
    if (checkTimeout) {
      this.checkTimeout();
    }
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";
    // headers["Access-Control-Allow-Origin"] = "*";
    if ((url.indexOf('/validatepin/') < 0 && url.indexOf('/proxy/') < 0) || url.indexOf('/proxy/customer/') > -1 || url.indexOf('/proxy/browse/') > -1) {
      const TOKEN = Util.getToken();
      if (!TOKEN) {
        const calledApiDetail = {
          url: url,
          method: "doPost",
          request: payload
        };
        return this.getToken('', calledApiDetail);
      } else {
        headers["Authorization"] = "Bearer "+TOKEN;
      }
    } else if (url.indexOf('/proxy/sfcc') >= 0) {
      if (!headers["Authorization"] && url.indexOf('/proxy/sfcc/customers/auth') < 0 || (url.indexOf('/proxy/sfcc/customers/auth') >= 0 && payload && payload.type && payload.type != "guest" && payload.type != "credentials")) {
        headers["Authorization"] = Util.getSFCCToken() || '';
      }
    }
      payload = payload || {};
      const conf = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload)
      };
      if (needPreloader) {
        Util.toggleHeaderLoader(true, sectionPreloader);
        apiStack.push(1);
      }
      return fetch(url, conf)
        .then(resp => {
          if (sfccTokenRefresh) {
            this.refreshSFCCToken();
          }
          const errorResponse = {
            network_error: true,
            status_code: 500,
            status_message: "Something went wrong in network"
          };
          if (needPreloader) {
            apiStack.pop();
            if (apiStack.length <= 0) {
              Util.toggleHeaderLoader(false);
            }
          }
          if (resp.ok) {
            return resp.json();
          } else if (resp.status === 401) {
            // Google Analytics: Access token expired
            dataLayer.push({
              errorValue: "Unauthorized api call",
              event: "errorLogged"
            });
            errorResponse.status_code = 401;
            errorResponse.status_code = 401;
            errorResponse.network_error = true;
            errorResponse.status_message = "Your session has expired";
            const calledApiDetail = {
              url: url,
              method: "doPost",
              request: payload
            };
            this.getToken('', calledApiDetail);
            return errorResponse;
          } else if (resp.status === 400) {
            errorResponse.status_code = 400;
            errorResponse.network_error = true;
            errorResponse.status_message = "Your session has expired";
            return errorResponse;
          } else {
            errorResponse.status_code = resp.status;
            errorResponse.network_error = true;
            errorResponse.status_message = "Somethig went wrong";
            throw errorResponse;
          }
        })
        .then(data => {
          if (url.indexOf('/proxy/sfcc') >= 0 && data && data.fault && data.fault.type && data.fault.type == 'ExpiredTokenException') {
            Util.clearSFCCSession();
            const history = Util.getCurrentHistoryObj();
            Util.gotoRoute('/account/login', history);
            Util.addStringToStorage(Util.IS_SIGNUP_SESSION_LOGGED_OUT, true);
            data.fault.message = 'Your session expired. Please login again!';
          }
          return data;
        })
        .catch(error => {
          console.log('API Error', error);
          let noNetworkString = '';
          if (error && error.name === 'TypeError' && error.message.indexOf(Util.NO_NETWORK_LABEL) >= 0) {
            noNetworkString = Util.NO_NETWORK_LABEL;
            // window.location.reload();
          } else {
            if (needPreloader) {
              apiStack.pop();
              if (apiStack.length <= 0) {
                Util.toggleHeaderLoader(false);
              }
            }
            const errorResponse = {
              network_error: true,
              status_code: noNetworkString,
              status_message: "Something went wrong in network"
            };
            throw errorResponse;
          }
        });
  }

  static doPut(url, payload, headers = {}, accessToken = true, sectionPreloader = false) {
    this.checkTimeout();
    headers["Content-Type"] = "application/json;";
    headers["Accept"] = "*";
    headers["Access-Control-Allow-Origin"] = "*";
    
    const TOKEN = Util.getToken();
    if (TOKEN) {
      headers["Authorization"] = "Bearer "+TOKEN;
    } else {
      const calledApiDetail = {
        url: url,
        method: "doPut",
        request: payload
      };
      return this.getToken('', calledApiDetail);
    }

    const conf = {
      method: "put",
      headers: headers,
      mode: "cors",
      body: JSON.stringify(payload)
    };
    Util.toggleHeaderLoader(true, sectionPreloader);
    return fetch(url, conf)
      .then(resp => {
        this.refreshSFCCToken();
        let errorResponse = {
          network_error: true,
          status_code: 500,
          status_message: "Something went wrong in network"
        };
        apiStack.pop();
        if (apiStack.length <= 0) {
          Util.toggleHeaderLoader(false);
        }
        if (resp.ok) {
          return resp.json();
        } else if (resp.status === 401) {
          // Google Analytics: Access token expired
          dataLayer.push({
            errorValue: "Unauthorized api call",
            event: "errorLogged"
          });
          errorResponse.status_code = 401;
          errorResponse.network_error = true;
          errorResponse.status_message = "Your session has expired";
          const calledApiDetail = {
            url: url,
            method: "doPut",
            request: payload
          };
          this.getToken('', calledApiDetail);
          return errorResponse;
        } else if (resp.status === 400) {
          errorResponse.status_code = 400;
          errorResponse.network_error = true;
          errorResponse.status_message = "Your session has expired";
          return errorResponse;
        } else {
          errorResponse.status_code = resp.status;
          errorResponse.network_error = true;
          errorResponse.status_message = "Somethig went wrong";
          throw errorResponse;
        }
      })
      .then(data => {
        if (url.indexOf('/proxy/sfcc') >= 0 && data && data.fault && data.fault.type && data.fault.type == 'ExpiredTokenException') {
          Util.clearSFCCSession();
          const history = Util.getCurrentHistoryObj();
          Util.gotoRoute('/account/login', history);
          Util.addStringToStorage(Util.IS_SIGNUP_SESSION_LOGGED_OUT, true);
          data.fault.message = 'Your session expired. Please login again!';
        }
        return data;
      })
      .catch(error => {
        let noNetworkString = '';
        if (error && error.stack === Util.NO_NETWORK_LABEL) {
          noNetworkString = Util.NO_NETWORK_LABEL;
        }
        apiStack.pop();
        if (apiStack.length <= 0) {
          Util.toggleHeaderLoader(false);
        }
        const errorResponse = {
          network_error: true,
          status_code: noNetworkString,
          status_message: "Something went wrong in network"
        };
        throw errorResponse;
      });
  }
  static getToken(pin = '', calledApiDetail = null) {
    const controller = new AbortController();
    controller.abort();

    const storeDetail = Util.getStoreDetails();
    const storePin = Util.getStorePin();
    const request = {
      storeId: storeDetail.storeId,
      pin: pin || storePin
    };
    let url = ApiUrlConstant.getApiUrl('startSession');
    url = Util.beautifyUrl(url, [storeDetail.storeId]);
    return this.doPost(url, request, true, {}, false, false)
      .then(data => {
        if (data && data.valid && data.token) {
          storeDetail.pin = request.pin;
          Util.setStorePin(request.pin);
          Util.addObjectToStorage(Util.STORE_DETAILS, storeDetail);
          Util.setToken(data.token);
          if (calledApiDetail && calledApiDetail.url) {
            return this[calledApiDetail.method](calledApiDetail.url, calledApiDetail.request);
          }
        } else {
          Util.clearStorePin();
          if (window.location.pathname.indexOf('/store/') < 0) {
            window.location.href = '/store/'+storeDetail.storeId+'?pinError=true';
          } else {
            Util.toggleHeaderLoader(true);
            window.location.href = '/store/'+storeDetail.storeId+'?pinError=true';
          }
        }
        return data;
      })
      .catch((e) => {
        Util.clearStorePin();
        // window.location.href = '/store/'+storeDetail.storeId+'?pinError=true';
        // if (!calledApiDetail || !calledApiDetail.url) {
        //   window.location.href = '/store/'+storeDetail.storeId;
        //   throw e;
        // } else {
        //   console.log(e);
        //   window.location.href = '/store/'+storeDetail.storeId;
        // }
      });
  }
}
