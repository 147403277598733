import React from 'react';
import closeIcon from '../../static/close.svg';
import './feedback-survey-popup.scss';
function Surveypopup(props) {
    return (
        <div className={"hamburger-menu-container "+ (props.surveyVisible ? "visible" : "")}>
                    {/* <div className="feedbackButton" onClick={() => this.setState({ surveyVisible: !this.state.surveyVisible })} > */}
                    <div className="feedbackButton" onClick={props.onSurveyPopUpClick} >
                    <img role="button" aria-label="Available Size Window Close" src={closeIcon} className="close" onClick={props.onSurveyPopUpClick}/>
                        <span> Feedback </span>
                    </div>
                    <iframe src="https://survey.medallia.com/?mieJI0-DSA"></iframe>
        </div>
    );
}
  
export default Surveypopup;