const defaultUser = {
  guestToken: "",
  wishlist: [],
  wishlistCount: 0
};

const defaultCustomer = {};

export default (state = defaultUser, action) => {
  switch (action.type) {
    case "SET_GUEST":
      return {
        ...state,
        guestToken: action.token
      };

    case "SET_CUSTOMER_DETAILS":
      return {
        ...state,
        customerId: action.customerId
      };

    case "UPDATE_WISHLIST":
      const currentWishlist = state.wishlist;
      return {
        ...state,
        wishlist: currentWishlist.concat(action.wishlist)
      }
    case "SET_WISHLIST":
      return {
        ...state,
        wishlist: action.wishlist
      }
    case "SET_WISHLIST_COUNT":
      return {
        ...state,
        wishlistCount: action.wishlistCount
      }
    case "CLEAR_WISHLIST":
      return {
        ...state,
        wishlistCount: 0,
        wishlist: []
      }

    default:
      return state;
  }
};

