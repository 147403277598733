const defaultProduct = {
    itemDetails: {},
    itemPriceInfo: {},
    similarProducts: {},
    selectedSize: '',
    selectedColor: '',
    similarItemsInventory: [],
    isSimilarItemsInventory:false,
    isSimilarItemsOtherStoreInventory:false,
    similarItemsOtherStoreInventory: [],
    onlineItemsInventory: [],
    similarItemsSoldOutInventory: [],
    recommendedItemsInventory: {},
    productListDetail: {
      productList: [],
      totalRecords: 0,
      scrollId: ''
    },
    categoryProductCount: []
};

export default (state = defaultProduct, action) => {
  switch (action.type) {
    case "SET_PRODUCT_INFO":
      return {
        ...state,
        itemDetails: action.itemDetails
      }
      // eslint-disable-next-line
      break;
    case "SET_PRODUCT_PRICING":
      return {
        ...state,
        itemPriceInfo: action.itemPriceInfo
      }
      // eslint-disable-next-line
      break;
    case "SET_SIMILAR_PRODUCTS":
      return {
        ...state,
        selectedSize: action.selectedSize,
        selectedColor: action.selectedColor,
        similarProducts: action.similarProducts
      }
      // eslint-disable-next-line
      break;
    case "SET_SIMILAR_PRODUCTS_INVENTORY":
      return {
        ...state,
        similarItemsInventory: action.similarItemsInventory,
        isSimilarItemsInventory: true
      }
      // eslint-disable-next-line
      break;
    case "SET_SIMILAR_PRODUCTS_SOLDOUT_INVENTORY":
      return {
        ...state,
        similarItemsSoldOutInventory: action.similarItemsSoldOutInventory
      };
      break;
    case "SET_SIMILAR_PRODUCTS_OTHER_STORE_INVENTORY":
      return {
        ...state,
        similarItemsOtherStoreInventory: action.similarItemsOtherStoreInventory,
        isSimilarItemsOtherStoreInventory:true
      }
      // eslint-disable-next-line
      break;
    case "SET_RECOMMENDED_PRODUCTS_INVENTORY":
      return {
        ...state,
        recommendedItemsInventory: action.recommendedItemsInventory
      }
      // eslint-disable-next-line
      break;
    case "SET_ONLINE_PRODUCTS_INVENTORY":
      return {
        ...state,
        onlineItemsInventory: action.onlineItemsInventory
      }
      // eslint-disable-next-line
      break;
    case "SET_PRODUCT_LISTING":
      return {
        ...state,
        productListDetail: action.productListDetail
      }
      // eslint-disable-next-line
      break;
    case "SET_CATEGORY_PRODUCT_COUNT":
      return {
        ...state,
        categoryProductCount: action.categoryProductCount
      }
      // eslint-disable-next-line
      break;
    default:
      return state;
  }
};
