const CATALOG = 'coach-us';
export default class ApiUrlConstant {
    static BASE_URL = window.location.origin.indexOf('localhost:') > -1 ? 'https://app.scan-dev.tapestry.com/' : window.location.origin.replace('https://', 'https://app.').replace('http://', 'http://app.')+'/';
    static API_VERSION = "api/";
    static API_VERSION_PROXY = "proxy/";
    static API_URLS = {
        storeInfo: "storeinfo/catalogs/"+CATALOG+"/stores/{?}",
        itemInfo: "iteminfo/catalogs/"+CATALOG+"/stores/{?}/items/{?}",
        validateCoupon: "validatecoupon/catalogs/"+CATALOG+"/stores/{?}",
        priceInfo: "priceinfo/catalogs/"+CATALOG+"/stores/{?}/items/{?}",
        cartInfo: "cartinfo/catalogs/"+CATALOG+"/stores/{?}",
        offers: "offerlists/catalogs/"+CATALOG+"/stores/{?}",
        startSession: "validatepin/catalogs/"+CATALOG+"/stores/{?}",
        similarItems: "iteminfo/catalogs/"+CATALOG+"/stores/{?}/retrive-similar-items/{?}",
        recommendedItems: "recommendations",
        passTheBasket: "basket",
        subscription: "subscription",
        inventory: "inventory",
        onlineInventory: "online/inventory",
        customerAuth: "sfcc/customers/auth",
        customerSignUp: "sfcc/customers",
        customerAccountDetails: "sfcc/customers/{?}",
        customer: "sfcc/customers/{?}",
        getWishlist: "sfcc/customers/{?}/product_lists/{?}/getItems?start={?}&count={?}",
        addToWishlist: "sfcc/customers/{?}/wishlist/addProduct",
        removeFromWishlist: "sfcc/customers/{?}/product_lists/{?}/items/{?}",
        updatePassword: "sfcc/customers/{?}/password",
        logout: "sfcc/customers/logout",
        personalizedPromos: "customer/promos",
        tax: "store/{?}/tax",
        wishlistSync: "sfcc/customers/{?}/wishlist/sync",
        triggerEmail: "trigger/email",
        validateSUCCoupon: "validate/suc",
        holdItem: 'holditem',
        inLineSettings: "storeinfo/catalogs/"+CATALOG+"/stores/{?}/shop-in-line-settings",
        productListing: 'browse/{?}',
        categoryInventory: "category-count/{?}",
        signupAlerts: "attentive/subscription"

    };
    static getApiUrl(key, isProxy = false) {
        if (isProxy) {
            return this.BASE_URL+this.API_VERSION_PROXY+this.API_URLS[key];
        } else {
            return this.BASE_URL+this.API_VERSION+this.API_URLS[key];
        }
    }
    static getBase() {
        return this.BASE_URL;
    }
    static getFullUrl(relativeUrl) {
        return this.BASE_URL+this.API_VERSION+relativeUrl;
    }
}