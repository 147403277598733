import Api from '../utils/api.service';
import ApiUrlConstant from '../utils/apiUrl.constant';
import Util from '../utils/util';
export default class CartService {
    static getCartDetails(storeId, request) {
        let url = ApiUrlConstant.getApiUrl('cartInfo');
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request);
    }
    static passTheBasket(storeId, request) {
        let url = ApiUrlConstant.getApiUrl('passTheBasket', true);
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request, true, {}, false, false, false);
    }
    static getTaxDetail(storeId, request) {
        let url = ApiUrlConstant.getApiUrl('tax', true);
        url = Util.beautifyUrl(url, [storeId]);
        return Api.doPost(url, request);
    }
}