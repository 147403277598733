const defaultStore = {
  isPinValid: true,
  isEmailOptIn: true
};

export default (state = defaultStore, action) => {
  switch (action.type) {
    case "SET_STORE":
      let storeDetail = action.store || {};
      storeDetail.isPinValid = state.isPinValid || (window.location.search.indexOf('pinError=true') > -1 ? false : true);
      if (storeDetail && storeDetail.countryCode == 'CAN') {
        storeDetail.isEmailOptIn = false;
      } else {
        storeDetail.isEmailOptIn = true;
      }
      return storeDetail;
    case "SET_PIN_VALIDITY":
        return {
          ...state,
          isPinValid: action.payload
        }
    case "SET_EMAIL_OPT_IN":
        return {
          ...state,
          isEmailOptIn: action.payload
        }
    default:
      return state;
  }
};
